import type { CycleDetailsItems } from '@/api';
import { useAppDispatch } from '@/app/store';
import { ProductImage } from '@/components/layout/ProductImage';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { useCycleCountContext } from '@/context/cycleCount';
import {
  setDecodedItemsConfirmed,
  setDecodedItemsNotConfirmed,
} from '@/features/cycleCount/cycleCountSlice';
import { useSelector } from '@/hooks/useSelector';
import React, { useCallback, useState } from 'react';

import {
  StyledAccordion,
  StyledAccordionItemContent,
  StyledContentInfo,
  StyledDecodedAccordionContent,
  StyledDecodedCheckbox,
  StyledDecodedProductContent,
  StyledDescription,
  StyledProductItem,
} from '../../listItemStyle';
import { ProductListItemProps } from '../types';

export const DecodedProductListItem: React.FC<
  ProductListItemProps & CycleDetailsItems
> = ({ type, disableActions, ...product }) => {
  const dispatch = useAppDispatch();

  const { setProductDetailsByUpc, setProductModalVisibility } =
    useCycleCountContext();
  const { decodedItemsConfirmed, decodedItemsNotConfirmed } = useSelector(
    state => state.cycleCount.products
  );

  const [accordionExpanded, setAccordionExpanded] = useState<boolean>(true);

  const accordionClickHandler = (): void => setAccordionExpanded(p => !p);

  //#region - COMMON FUNCTIONS
  const handleProductImageClick = (): void => {
    setProductDetailsByUpc(product.upcCode);
    setProductModalVisibility(true);
  };

  const isEpcChecked = useCallback(
    (epcCode: string): boolean => {
      return !!decodedItemsConfirmed?.find(item =>
        item.epcCodes.find(epc => epc.epcCode === epcCode)
      );
    },
    [decodedItemsConfirmed]
  );

  const handleEpcCheckboxClick = (epcCode: string): void => {
    let actualDecodedItemsConfirmed: CycleDetailsItems[] = JSON.parse(
      JSON.stringify(decodedItemsConfirmed || [])
    );
    let actualDecodedItemsNotConfirmed: CycleDetailsItems[] = JSON.parse(
      JSON.stringify(decodedItemsNotConfirmed || [])
    );

    if (!decodedItemsConfirmed && !decodedItemsNotConfirmed) {
      dispatch(setDecodedItemsConfirmed({ decodedItemsConfirmed: [product] }));
      return;
    }

    const findItemIndex = (list: CycleDetailsItems[], upc: string):number => list.findIndex(el => el.upcCode === upc);

    const upcConfirmedIndex = findItemIndex(actualDecodedItemsConfirmed, product.upcCode);
    const upcNotConfirmedIndex = findItemIndex(actualDecodedItemsNotConfirmed, product.upcCode);

    const moveEpc = (fromList: CycleDetailsItems[], fromIndex: number, toList: CycleDetailsItems[], epcCode: string): void => {
      const epcIndex = fromList[fromIndex].epcCodes.findIndex(epc => epc.epcCode === epcCode);
      if (epcIndex >= 0) {
        const epcItem = fromList[fromIndex].epcCodes.splice(epcIndex, 1)[0];
        const targetIndex = findItemIndex(toList, product.upcCode);
        if (targetIndex >= 0) {
          toList[targetIndex].epcCodes.push(epcItem);
        } else {
          toList.push({ ...product, epcCodes: [epcItem] });
        }
      }
    };

    // upc exists in confirmed and doesn't exist in not confirmed -> DECHECK
    if (upcConfirmedIndex >= 0 && upcNotConfirmedIndex < 0) {
      // epc has to be removed from confirmed and added to not confirmed
      moveEpc(actualDecodedItemsConfirmed, upcConfirmedIndex, actualDecodedItemsNotConfirmed, epcCode);

      // epcCodes list in confirmed has one element -> upc has to be removed from confirmed and added to not confirmed
      if (actualDecodedItemsConfirmed[upcConfirmedIndex].epcCodes.length === 0) {
        actualDecodedItemsConfirmed.splice(upcConfirmedIndex, 1);
      }
    }
    // upc exists in confirmed and exists in not confirmed ->
    else if (upcConfirmedIndex >= 0 && upcNotConfirmedIndex >= 0) {
      const epcExistsInConfirmed = actualDecodedItemsConfirmed[upcConfirmedIndex].epcCodes.some(epc => epc.epcCode === epcCode);
      const epcExistsInNotConfirmed = actualDecodedItemsNotConfirmed[upcNotConfirmedIndex].epcCodes.some(epc => epc.epcCode === epcCode);

      if (epcExistsInConfirmed && !epcExistsInNotConfirmed) {
        moveEpc(actualDecodedItemsConfirmed, upcConfirmedIndex, actualDecodedItemsNotConfirmed, epcCode);
      } else if (!epcExistsInConfirmed && epcExistsInNotConfirmed) {
        moveEpc(actualDecodedItemsNotConfirmed, upcNotConfirmedIndex, actualDecodedItemsConfirmed, epcCode);
      }
    }
    // upc doesn't exist in confirmed and exists in not confirmed -> CHECK
    else if (upcConfirmedIndex < 0 && upcNotConfirmedIndex >= 0) {
      // epc has to be removed from not confirmed and added to confirmed
      moveEpc(actualDecodedItemsNotConfirmed, upcNotConfirmedIndex, actualDecodedItemsConfirmed, epcCode);

      // epcCodes in not confirmed has one element -> upc has to be removed from not confirmed and added to confirmed
      if (actualDecodedItemsNotConfirmed[upcNotConfirmedIndex].epcCodes.length === 0) {
        actualDecodedItemsNotConfirmed.splice(upcNotConfirmedIndex, 1);
      }
    }

    dispatch(
      setDecodedItemsConfirmed({
        decodedItemsConfirmed: actualDecodedItemsConfirmed,
      })
    );
    dispatch(
      setDecodedItemsNotConfirmed({
        decodedItemsNotConfirmed: actualDecodedItemsNotConfirmed,
      })
    );
  };
  //#endregion

  return (
    <>
      <StyledProductItem>
        <StyledDecodedProductContent $type={type}>
          <StyledContentInfo>
            {product.epcCodes.length > 1 && (
              <StyledAccordion
                expanded={accordionExpanded}
                onClick={accordionClickHandler}
              />
            )}
            <ProductImage
              imageUrl={product.imageUrl}
              colorCode={product.colorCode}
              modelCode={product.modelCode}
              brandCode={product.brandCode}
              onClick={handleProductImageClick}
            />
            <UIBox flexDirection="column">
              <StyledDescription>
                <UIBox alignItems="center">
                  <Typography size="lg" font="heavy" as="span">
                    {product.brandCode || product.brandDescription} -{' '}
                    {product.brandDescription} - {product.styleName}
                  </Typography>
                </UIBox>
                <Typography margin="8px 0 0 0" size="md" font="book" as="span">
                  {product.upcCode} - {product.modelCode} - {product.commodity}
                </Typography>
              </StyledDescription>
            </UIBox>
          </StyledContentInfo>
        </StyledDecodedProductContent>
        {product.epcCodes.length > 0 &&
          accordionExpanded &&
          <StyledDecodedAccordionContent ml="0px">
            {product.epcCodes?.map(({ epcCode }) => (
              <StyledAccordionItemContent key={epcCode}>
                <UIBox alignItems="center">
                  <StyledDecodedCheckbox
                    checked={isEpcChecked(epcCode)}
                    disabled={disableActions}
                    onClick={(): void => handleEpcCheckboxClick(epcCode)}
                  />
                  <UIBox alignItems="center">
                    <Typography margin="0 0 0 12px" color="grey">
                      {epcCode}
                    </Typography>
                  </UIBox>
                </UIBox>
              </StyledAccordionItemContent>
            ))}
          </StyledDecodedAccordionContent>
        }
      </StyledProductItem>
    </>
  );
};

export default DecodedProductListItem;
