import { ApiError } from '@/api/bff';
import { HdaUserRequest, HdaUserService } from '@/api/users';
import { RootState } from '@/app/rootReducer';
import {
  HdaUsersInitialState,
} from '@/types/profiling';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: HdaUsersInitialState = {
  list: [],
};

export const fetchHdaUsers = createAsyncThunk<
  HdaUserRequest[],
  string | undefined,
  {
    state: RootState;
    rejectWithvalue: ApiError;
  }
>('profiling/fetchHDAUsers', async (userId, { rejectWithValue }) => {
  try {
    const response = await HdaUserService.getHdaUsers(userId ? {
      userId
    }: {});

    return response ?? [];
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const upsertHdaUser = createAsyncThunk<
  void,
  Parameters<typeof HdaUserService.upsertHdaUser>[number],
  {
    state: RootState;
    rejectWithvalue: ApiError;
  }
>('profiling/upsertHDAUser', async ({ userId, requestBody }, { rejectWithValue }) => {
  try {
    return await HdaUserService.upsertHdaUser({
      userId: userId || requestBody.userId,
      requestBody: requestBody,
    });
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

export const removeHDAUser = createAsyncThunk<
  void,
  Parameters<typeof HdaUserService.deleteHdaUser>[number],
  {
    state: RootState;
    rejectWithvalue: ApiError;
  }
>('profiling/removeHDAUser', async ({ userId }, { rejectWithValue }) => {
  try {
    return await HdaUserService.deleteHdaUser({
      userId,
    });
  } catch (err) {
    return rejectWithValue(err as ApiError);
  }
});

const hdaUserProfilingSlice = createSlice({
  name: 'hdaUserProfiling',
  initialState,
  reducers: {
    removeHdaUserFromRedux: (state, { payload }: PayloadAction<string>) => {
      state.list = state.list.filter(({ userId }) => userId !== payload);
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    initProfilingHdaUserState: () => initialState,
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
  },
  extraReducers: builder => {
    builder
      .addCase(fetchHdaUsers.pending, state => {
        state.fetchHdaUsersIsLoading = true;
        state.fetchHdaUsersHasError = undefined;
      })
      .addCase(fetchHdaUsers.fulfilled, (state, { payload }) => {
        if (payload) {
          state.fetchHdaUsersIsLoading = false;
          state.list = payload;
        }
      })
      .addCase(fetchHdaUsers.rejected, (state, { payload }) => {
        state.fetchHdaUsersIsLoading = false;
        state.fetchHdaUsersHasError = payload as ApiError;
      })
      .addCase(removeHDAUser.pending, state => {
        state.removeHdaUserIsLoading = true;
        state.removeHdaUserHasError = undefined;
      })
      .addCase(removeHDAUser.fulfilled, state => {
        state.removeHdaUserIsLoading = false;
      })
      .addCase(removeHDAUser.rejected, (state, { payload }) => {
        state.removeHdaUserIsLoading = false;
        state.removeHdaUserHasError = payload as ApiError;
      })
      .addCase(upsertHdaUser.pending, state => {
        state.upsertHdaUserIsLoading = true;
        state.upsertHdaUserHasError = undefined;
      })
      .addCase(upsertHdaUser.fulfilled, state => {
        state.upsertHdaUserIsLoading = false;
      })
      .addCase(upsertHdaUser.rejected, (state, { payload }) => {
        state.upsertHdaUserIsLoading = false;
        state.upsertHdaUserHasError = payload as ApiError;
      })
  },
});

export const {
  removeHdaUserFromRedux,
  initProfilingHdaUserState,
} = hdaUserProfilingSlice.actions;
export default hdaUserProfilingSlice.reducer;
