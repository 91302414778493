import { HdaUserRequest } from '@/api/users';
import { AppRoutes } from '@/app/routers';
import { UIAction } from '@/components/ui/Action';
import { useSelector } from '@/hooks/useSelector';
import { isAdmin } from '@/utils/user';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  StyledActionsWrapper,
  StyledGridRow,
  StyledRowWrapper,
  StyledSpan,
  StyledStatus,
  StyledStatusWrapper,
  StyledUserId,
} from '../ProfilingUsersList/style';
import { StyledGridRowContent } from './style';
import { ProfilingUsersRowProps } from './types';

const ProfilingHDAUsersRow: React.FC<HdaUserRequest & ProfilingUsersRowProps> = ({
  userId,
  name,
  surname,
  email,
  statusUser,
  setUserId,
  setAttentionModalVisibility,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useSelector(state => state.user);

  const { roles } = useSelector(state => state.user);

  const onRemoveClick = (userId: string): void => {
    setUserId(userId);
    setAttentionModalVisibility(true);
  };

  const onEditClick = (userId: string): void => {
    history.push(`${AppRoutes.MANAGE_HDA_USERS_EDIT}?userId=${userId}`);
  };

  return (
    <StyledRowWrapper>
      <StyledGridRow key={userId}>
        <StyledGridRowContent $isUserAdmin={isAdmin(user)}>
          <StyledUserId>
            <StyledSpan font="medium">{userId}</StyledSpan>
          </StyledUserId>
          <StyledSpan font="medium">
            {name} {surname}
          </StyledSpan>
          <StyledSpan font="medium">{email}</StyledSpan>
          <StyledStatusWrapper>
            <StyledStatus status={statusUser} />
            <StyledSpan font="medium">
              {t(`${statusUser?.toLowerCase()}`)}
            </StyledSpan>
          </StyledStatusWrapper>
          <StyledActionsWrapper>
            <UIAction
              icon="delete"
              label=""
              disabled={
                roles?.includes('Admin') && !user.roles?.includes('Admin')
              }
              onClick={(): void => onRemoveClick(userId!)}
            />
            <UIAction
              icon="edit"
              label=""
              disabled={
                roles?.includes('Admin') && !user.roles?.includes('Admin')
              }
              onClick={(): void => onEditClick(userId!)}
            />
          </StyledActionsWrapper>
        </StyledGridRowContent>
      </StyledGridRow>
    </StyledRowWrapper>
  );
};

export default ProfilingHDAUsersRow;
