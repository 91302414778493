import { UIBox } from '@/components/ui/Box';
import { theme } from '@/theme';

import styled, { css } from 'styled-components';

export const GridStyles = css`
  display: grid;
  align-items: center;
  gap: 16px;

  grid-template-columns: 10% 20% auto 15% 10%;

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 10% 20% auto 15% 10%;
  }
`;

export const StyledGridHeader = styled(UIBox)`
  width: 100%;
  padding: 8px 24px;
  border-bottom: 1px solid #4b4b4b;
  ${GridStyles}
`;

export const StyledGridRowContent = styled(UIBox)<{
  $showPartial?: boolean;
  $isUserAdmin?: boolean;
}>`
  width: 100%;
  padding: 8px 0;

  ${GridStyles}
  & > * {
    &:last-child {
      width: 100%;
      justify-self: flex-end;
      justify-content: end;
    }
  }
`;
