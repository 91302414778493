import { User } from '@/api/users';
import { useAppDispatch } from '@/app/store';
import { ModalAttention } from '@/components/layout/ModalAttention';
import { PaginationList } from '@/components/layout/PaginationList';
import { UICheckbox } from '@/components/ui/Checkbox';

import { Typography } from '@/components/ui/Typography';

import {
  removeUser,
  removeUserFromRedux,
} from '@/features/profiling/usersSlice';
import { isAdmin } from '@/utils/user';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useUpdateEffect } from 'react-use';
import ProfilingUsersRow from './ProfilingUsersRow';
import { StyledGrid, StyledGridHeader } from './style';
import { ProfilingUserListProps, TUserSelect } from './types';

const ProfilingUsersList: React.FC<ProfilingUserListProps> = ({
  users,
  $showPartial,
  setAlertSnackbarVisibility,
  exportUsersSelected,
  externalFn,
}) => {
  const history = useHistory();
  const isUserPage = history.location.pathname
    .split('/')
    .find((pathEl: string) => pathEl === 'manage-users');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [id, setUserId] = useState<string>();
  const [userSelected, setUserSelected] = useState<TUserSelect | undefined>();
  const [usersSelected, setUsersSelected] = useState<{ [k: string]: boolean }>(
    {}
  );
  const [allUsersSelected, setAllUsersSelected] = useState<boolean>(false);

  const user = useSelector(state => state.user);

  const { roles } = useSelector(state => state.user);

  const [attentionModalIsVisible, setAttentionModalVisibility] =
    useState<boolean>(false);

  useUpdateEffect(() => {
    if (userSelected) {
      setUsersSelected(prev => ({
        ...prev,
        ...{ [userSelected.user]: userSelected.checked },
      }));
    }
  }, [userSelected]);

  useUpdateEffect(() => {
    exportUsersSelected?.(users.filter(k => usersSelected[k.userId!]));
    const length = Object.values(usersSelected).filter(value => value).length;

    // set "all" checkbox value to false
    if (length !== users.length) {
      setAllUsersSelected(false);
    }
    // set "all" checkbox value to true
    else if (length === users.length) {
      setAllUsersSelected(true);
    }
  }, [usersSelected]);

  const handleAllUsersCheck = (): void => {
    // unselect all
    if (allUsersSelected) {
      setUsersSelected({});
    }
    // select all
    else {
      const usersSelected = users.reduce(
        (acc: { [k: string]: boolean }, user) => {
          if (user.userId) {
            acc = {
              ...acc,
              [user.userId]: true,
            };
          }
          return acc;
        },
        {}
      );

      setUsersSelected(usersSelected);
    }

    // set "all" checkbox value
    setAllUsersSelected(!allUsersSelected);
  };

  const onModalCloseClick = (): void => {
    setAttentionModalVisibility(false);
    setUserId(undefined);
  };

  const onConfirmRemoveClick = async (): Promise<void> => {
    try {
      if (id) {
        await dispatch(removeUser({ userId: id })).unwrap();
        dispatch(removeUserFromRedux(id));
        setAttentionModalVisibility(false);
      }
    } catch (err) {
      setAlertSnackbarVisibility?.(true);
    }
  };

  return (
    <>
      <ModalAttention
        open={attentionModalIsVisible}
        onConfirmClick={onConfirmRemoveClick}
        onClose={onModalCloseClick}
        message={t('attentionDeleteUser')}
      />
      <StyledGrid>
        <StyledGridHeader
          $showPartial={$showPartial}
          $isUserAdmin={isAdmin(user)}
        >
          {isAdmin(user) && isUserPage ? (
            <>
              <Typography font="heavy">
                {users?.length > 0 && (
                  <UICheckbox
                    color="primary"
                    onChange={handleAllUsersCheck}
                    checked={allUsersSelected}
                  />
                )}
              </Typography>

              <Typography font="heavy" margin={'0 0 0 40px'}>
                {t('userId')}
              </Typography>
            </>
          ) : (
            <Typography font="heavy" margin={'0 0 0 40px'}>
              {t('userId')}
            </Typography>
          )}
          <Typography font="heavy">{t('fullName')}</Typography>
          <Typography font="heavy">{t('role')}</Typography>
          {!$showPartial ? (
            <>
              <Typography font="heavy">{t('salesOrganization')}</Typography>
              <Typography font="heavy">{t('defaultStore')}</Typography>
            </>
          ) : (
            <Typography font="heavy">{t('jobTitle')}</Typography>
          )}
          <Typography font="heavy">{t('statusUser')}</Typography>
        </StyledGridHeader>

        <PaginationList
          data={users}
          pageSize={20}
          renderItem={(user: User): JSX.Element =>
            user && (
              <ProfilingUsersRow
                {...user}
                key={user.userId}
                setUserId={setUserId}
                $showPartial={$showPartial}
                setAttentionModalVisibility={setAttentionModalVisibility}
                exportUserSelected={setUserSelected}
                checked={usersSelected?.[user?.userId || ''] || false}
                isCheckboxVisible={roles?.includes('Admin')}
                selectedUser={user}
                externalFn={externalFn}
                users={[user]}
              />
            )
          }
        />
      </StyledGrid>
    </>
  );
};

export default ProfilingUsersList;
