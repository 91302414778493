export enum Roles {
	ALL = 'all',
	ADMIN = 'admin',
	OPERATOR = 'operator',
}

export enum Statuses {
	ALL = 'all',
	ACTIVE = 'Active',
	INACTIVE = 'Inactive',
}

export type RoleFilter = string | 'all';
export type StatusFilter = string | 'all';
export type ProcessFilter = string | 'all';

export type UsersFilters = {
	userId: string;
	surname: string;
	storeCode: string;
  defaultStoreCode: boolean;
	roleName: RoleFilter;
	salesOrganization?: string;
  userStatuses: StatusFilter;
};

export type HDAUsersFilters = {
	userId: string;
	surname: string;
};

export type StoreFilters = {
	sales: string;
	storeCode: string;
	role?: RoleFilter;
};

export type ReasonCodeFilters = {
	groupTitle: string;
	process: ProcessFilter;
	sales: string;
};

export type MovementTypeFilters = {
	typeCode: string;
	reasonCode: string;
};

export type ScannedProductFilters = {
	upcCode: string;
};
