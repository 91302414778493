import { AppRoutes } from '@/app/routers';
import { useAppDispatch } from '@/app/store';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { CycleCountListItem } from '@/components/layout/CycleCountListItem';
import { UIBox } from '@/components/ui/Box';

import { UIList } from '@/components/ui/List';
import { PageLoader } from '@/components/ui/PageLoader';
import { Typography } from '@/components/ui/Typography';

import {
  getCycleCountList,
  initCycleCountProducts,
  setMissingItemsCycleCount,
} from '@/features/cycleCount/cycleCountSlice';
import useResetDevice from '@/hooks/useResetDevice';
import { useSelector } from '@/hooks/useSelector';
import { Link } from '@material-ui/core';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useAsync } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import { StyledLinkWrapper } from './style';

const PageCycleCount: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { store } = useSelector(state => state.currentStore);
  const deviceInUse = useSelector(state => state.devices.deviceInUse);
  const { resetDevice } = useResetDevice();

  const {
    cycleCountItems,
    cycleCountItemsIsLoading,
    isMissingItemsCycleCount,
  } = useSelector(state => state.cycleCount);

  useEffect(() => {
    if (store?.storeCode) {
      dispatch(
        getCycleCountList({
          storeId: store.storeCode,
          dayBefore: 20,
        })
      );
    }
  }, [dispatch, store?.storeCode]);

  useAsync(async () => {
    if (deviceInUse) {
      await resetDevice();
    }
  }, []);

  useEffect(() => {
    if (isMissingItemsCycleCount) {
      dispatch(setMissingItemsCycleCount(false));
    }
  }, [isMissingItemsCycleCount, dispatch]);

  useEffect(() => {
    dispatch(initCycleCountProducts());
  }, [dispatch]);

  const onNewCycleCountClick = (): void => {
    sessionStorage.setItem('linkedEventId', uuidv4());
    history.push(AppRoutes.CYCLE_COUNT_FILTER_BRAND);
  };

  if (cycleCountItemsIsLoading) {
    return <PageLoader />;
  }

  return (
    <UIBox position="relative" width="100%">
      <StyledLinkWrapper>
        <Link
          component="button"
          onClick={(): void =>
            history.push(AppRoutes.CYCLE_COUNT_MISSING_ITEMS)
          }
        >
          <Typography size="lg" color="primary">
            {t('showMissingItems')}
          </Typography>
        </Link>
      </StyledLinkWrapper>
      <UIList title={t('cycleCount.historytitle')} padding={[24, 24, 100, 24]}>
        {cycleCountItems?.map((props, index) => (
          <CycleCountListItem key={index} {...props} />
        ))}
      </UIList>
      <CTAContainer type="NEW CYCLE COUNT" onClick={onNewCycleCountClick} />
    </UIBox>
  );
};

PageCycleCount.displayName = 'PageCycleCount';

export default PageCycleCount;
