import { HdaUserRequest } from '@/api/users';
import { AppRoutes } from '@/app/routers';
import { useAppDispatch } from '@/app/store';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { Form } from '@/components/layout/Form';
import { ModalAttention } from '@/components/layout/ModalAttention';
import { Tabs } from '@/components/layout/Tabs';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';
import { UIBox } from '@/components/ui/Box';
import { PageLoader } from '@/components/ui/PageLoader';
import SelectController from '@/components/ui/Select/SelectController';
import { UISwitch } from '@/components/ui/Switch';
import TextFieldController from '@/components/ui/TextField/TextFieldController';
import {
  fetchHdaUsers,
  upsertHdaUser,
} from '@/features/profiling/hdaUsersSlice';
import { useSelector } from '@/hooks/useSelector';
import { FormInputs } from '@/types/hookFormInput';
import { StatusHdaUser } from '@/types/profiling';
import qs from 'query-string';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useAsync } from 'react-use';
import styled from 'styled-components';

//#region - Styled Components
const StyledSwitchWrapper = styled(UIBox)`
  width: 100%;
  margin: 12px 32px 0 0;

  & > * {
    margin-left: auto;
  }
`;

//#endregion

interface LocationState {
  fromStore: boolean;
  storeToRedirect?: string;
}

const PageControlHDAUsersEdit: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { t, i18n } = useTranslation();
  const { search } = useLocation<LocationState>();

  const tabs = [t('personalData')];
  const query = useMemo(() => qs.parse(search), [search]);

  const loggedUser = useSelector(state => state.user);
  const {
    list: formUserDetailsArray,
    fetchHdaUsersIsLoading,
    upsertHdaUserIsLoading,
    upsertHdaUserHasError,
  } = useSelector(state => state.profiling.hdaUser);

  const formUserDetails: HdaUserRequest = useMemo(
    () => {
      return {
        ...formUserDetailsArray[0],
        language: formUserDetailsArray[0].language?.toLowerCase() || ''
      }
    },
    [formUserDetailsArray]
  );

  const [resetValues] = useState<boolean>(true);
  const [selectedTab] = useState<number>(0);
  const [activeUser, setActiveUser] = useState<StatusHdaUser>();
  const [saveEnable, setSaveEnable] = useState<boolean>(false);

  const [attentionModalIsVisible, setAttentionModalVisibility] =
    useState<boolean>(false);

  const [attentionBackModalIsVisible, setAttentionBackModalVisibility] =
    useState<boolean>(false);

  const [alertSaveUserData, setAlertSaveUserDataVisibility] =
    useState<boolean>(false);

  useAsync(async () => {
    if (query) {
      await dispatch(fetchHdaUsers(query.userId as string));
    }
  }, [dispatch]);

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { isDirty },
    getValues,
  } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: {
      userId: '',
      jobTitle: '',
      name: '',
      surname: '',
      email: '',
      language: '',
    },
  });

  const setDefaultFormDetails = useCallback((): void => {
    reset({ ...formUserDetails });
  }, [formUserDetails, reset]);

  useEffect(() => {
    if (isDirty) {
      setSaveEnable(true);
    }
  }, [activeUser, isDirty, saveEnable]);

  useEffect(() => {
    if (formUserDetails.statusUser) {
      setActiveUser(formUserDetails.statusUser);
    }
  }, [formUserDetails.statusUser]);

  useEffect(() => {
    if (resetValues) {
      setDefaultFormDetails();
    }
  }, [resetValues, setDefaultFormDetails]);

  const isEmailFormatValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
    getValues('email')
  );

  const isFormValid =
    getValues('userId') !== '' &&
    getValues('name') !== '' &&
    getValues('surname') !== '' &&
    getValues('language') !== '';

  const confirmDisabled =
    !isFormValid || (getValues('email') !== '' && !isEmailFormatValid);

  const redirectTo = (): void => {
    history.push(AppRoutes.MANAGE_HDA_USERS);
  };

  const onFormSubmit: SubmitHandler<FormInputs> = async (
    userData
  ): Promise<void> => {
    const { userId, language, name, surname, email } = userData;

    try {
      const updateUserBody: { userId: string; requestBody: HdaUserRequest } = {
        userId: String(formUserDetails['userId']),
        requestBody: {
          userId: userId.toUpperCase(),
          language,
          name: name,
          surname: surname,
          email,
          statusUser: activeUser,
        },
      };
      await dispatch(upsertHdaUser(updateUserBody)).unwrap();
      await reset(userData);
      setSaveEnable(false);

      if(formUserDetails['language'] !== language && formUserDetails['userId'] === loggedUser.username) {
        i18n.changeLanguage(language);
      }

      history.push(AppRoutes.MANAGE_HDA_USERS);
    } catch {
      setSaveEnable(true);
      setAlertSaveUserDataVisibility(true);
    }
  };

  const onSaveClick = async (): Promise<void> => {
    await handleSubmit(onFormSubmit)();
  };

  const onConfirmUndoModalClick = async (): Promise<void> => {
    setSaveEnable(false);
    setActiveUser(formUserDetails.statusUser as StatusHdaUser);
    await setDefaultFormDetails();

    setAttentionModalVisibility(false);
  };

  const onConfirmBackModalClick = async (): Promise<void> => {
    await onSaveClick();
    setAttentionBackModalVisibility(false);
    redirectTo();
  };

  const onCloseUndoModalClick = (): void => {
    setAttentionModalVisibility(false);
  };

  const onCloseBackModalClick = (): void => {
    setAttentionBackModalVisibility(false);
  };

  const onUnConfirmModalClick = async (): Promise<void> => {
    setAttentionModalVisibility(false);
    redirectTo();
  };

  const onBackClick = (): void => {
    if (saveEnable) {
      return setAttentionBackModalVisibility(true);
    }

    redirectTo();
  };

  if (fetchHdaUsersIsLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <ModalAttention
        open={attentionBackModalIsVisible}
        onConfirmClick={onConfirmBackModalClick}
        onUnconfirmClick={onUnConfirmModalClick}
        onClose={onCloseBackModalClick}
        message={t('modal.attention.messageSave')}
        disableConfirm={!saveEnable || confirmDisabled}
      />
      <ModalAttention
        open={attentionModalIsVisible}
        onConfirmClick={onConfirmUndoModalClick}
        onClose={onCloseUndoModalClick}
        message={t('modal.attention.message')}
      />
      <Tabs mt="18px" selectedTab={selectedTab} values={tabs} />
      <>
        <StyledSwitchWrapper>
          <UISwitch
            checked={activeUser === 'ACTIVE'}
            checkedLabel={t('activeUser')}
            unCheckedLabel={t('inactiveUser')}
            onChange={(): void => {
              setSaveEnable(prevState => !prevState);
              setActiveUser(prevState =>
                prevState === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
              );
            }}
          />
        </StyledSwitchWrapper>
        <Form>
          <TextFieldController
            required
            uppercase
            name="userId"
            control={control}
            value={watch('userId')}
            isErrorVisible={getValues('userId') === ''}
          />
          <TextFieldController
            required
            name="name"
            control={control}
            value={watch('name')}
            isErrorVisible={getValues('name') === ''}
          />
          <TextFieldController
            required
            name="surname"
            control={control}
            value={watch('surname')}
            isErrorVisible={getValues('surname') === ''}
          />
          <TextFieldController
            required
            name="email"
            control={control}
            value={watch('email')}
            isErrorVisible={getValues('email') !== '' && !isEmailFormatValid}
          />
          <SelectController
            required
            name="language"
            control={control}
            options={(i18n.languages || []) as string[]}
            value={watch('language') || ''}
            isErrorVisible={getValues('language') === ''}
          />
        </Form>
      </>
      <CTAContainer
        type="UNDO"
        onConfirmClick={(): Promise<void> => onSaveClick()}
        onQuitClick={onBackClick}
        onUndoClick={(): void => {
          setAttentionModalVisibility(true);
        }}
        disabled={upsertHdaUserIsLoading}
        loadingConfirm={upsertHdaUserIsLoading}
        undoDisabled={!saveEnable || upsertHdaUserIsLoading}
        disabledConfirm={!saveEnable || confirmDisabled}
      />

      <AlertSnackbar
        open={alertSaveUserData && !!upsertHdaUserHasError}
        setIsOpen={setAlertSaveUserDataVisibility}
        message={upsertHdaUserHasError?.body}
      />
    </>
  );
};

export default PageControlHDAUsersEdit;
