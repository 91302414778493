import { CycleDetailsItems } from '@/api';
import {
  StartSohAlighmentRequest,
  StockOnHandAlignmentService,
} from '@/api/receive';
import { AppRoutes } from '@/app/routers';
import { useAppDispatch } from '@/app/store';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { ModalDataSavedError as Modal } from '@/components/layout/ModalDataSaved';
import { ModalProductDetails } from '@/components/layout/ModalProductDetails';
import { ModalScanDetailsV2 } from '@/components/layout/ModalScanDetailsV2';
import { PaginationList } from '@/components/layout/PaginationList';
import { ScannedProductItemCycleCount } from '@/components/layout/ScannedProductItemCycleCount';

import { UIBox } from '@/components/ui/Box';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { UIList } from '@/components/ui/List';
import { PageLoader } from '@/components/ui/PageLoader';
import { Typography } from '@/components/ui/Typography';
import {
  getProductsByFilters,
  resetFindItems,
} from '@/features/cycleCount/cycleCountSlice';
import useResetDevice from '@/hooks/useResetDevice';
import { useSelector } from '@/hooks/useSelector';
import { buildCCFilters } from '@/utils/cycleCountFilters';
import { FC, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAsync } from 'react-use';
import styled from 'styled-components';

//#region - Styled Components

const StyledPageContainer = styled(UIBox)`
  width: 100%;
  flex-direction: column;

  & > * {
    padding: 0 24px;
  }
`;

const StyledTitle = styled(Typography)`
  margin: 24px 0 24px;
  text-align: center;
`;

//#endregion

const PageCycleCountFilterSummary: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [openModalScan, setOpenModalScanVisbility] = useState<boolean>(false);
  const [noItemsModalIsVisible, setNoItemsModalVisibility] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { resetDevice } = useResetDevice();

  // * MODALS STATE
  // ** Product details modal
  const [openProductModal, setProductModalVisibility] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');

  const [errorStartSoh, setErrorStartSoh] = useState<boolean>(false);

  const {
    brandList,
    selectedBrands,
    selectedEpc,
    selectedUpc,
    selectedModel,
    filterSelected,
    selectedMarketingThemes,
    selectedMarketingStories,
    brandsMarketingStories,
    brandsMarketingThemes,
    commodityState,
    productsIsLoading,
  } = useSelector(state => state.cycleCount);
  const cycleCount = useSelector(state => state.cycleCount);
  const { store } = useSelector(state => state.currentStore);
  const isSohProcess = useSelector(state => state.cycleCount.sohLocked);

  const { notFound, checkoutErrorList, missing, expectedItems } = useSelector(
    state => state.cycleCount.products
  );

  useAsync(async () => {
    if (errorStartSoh) {
      await resetDevice();
    }
  }, [errorStartSoh]);

  const onProductClick = (e: React.MouseEvent, upcCode: string): void => {
    e.preventDefault();

    const target = e.target as HTMLElement;

    if (
      typeof target.className === 'object' ||
      target.nextElementSibling instanceof SVGElement ||
      target.className.includes('Accordion')
    )
      return;

    setSelectedProduct(upcCode);
    setProductModalVisibility(true);
  };

  const brandCodes = useMemo(
    () => selectedBrands.map(({ brandCode }) => brandCode!),
    [selectedBrands]
  );

  const commodityCodes = useMemo(() => {
    if (commodityState.all) {
      return brandList?.commodityList
        ?.map(({ commodityCode }) => commodityCode!)
        .filter(code => code !== 'ALL');
    }

    return brandList?.commodityList
      ?.filter(({ commodityDescription }) => {
        if (
          commodityDescription &&
          commodityState.hasOwnProperty(commodityDescription.toLowerCase())
        ) {
          return commodityState[
            commodityDescription.toLowerCase() as keyof typeof commodityState
          ];
        }
        return false;
      })
      .map(({ commodityCode }) => commodityCode!);
  }, [brandList?.commodityList, commodityState]);

  useEffect(() => {
    if (store?.storeCode && brandList) {
      // let data: { [key: string]: CustomBrand[] | string[] | undefined } = {};
      //
      // const marketingThemeCodes = selectedMarketingThemes.map(
      //   ({ marketingThemeCode }) => marketingThemeCode!
      // );
      //
      // const marketingStoryCodes = selectedMarketingStories.map(
      //   ({ marketingStoryCode }) => marketingStoryCode!
      // );
      //
      // switch (filterSelected) {
      //   case 0:
      //     data = {
      //       brandCodes,
      //       marketingStoryCodes,
      //       marketingThemeCodes,
      //       commodityCodes,
      //     };
      //     break;
      //   case 1:
      //     data = { upcList: selectedUpc };
      //     break;
      //   case 2:
      //     data = { epcList: selectedEpc };
      //     break;
      //   case 3:
      //     data = {
      //       upcList: selectedModel.map(upcModel => upcModel.split('|')[0]),
      //     };
      //     break;
      //   default:
      //     break;
      // }
      const data = buildCCFilters({ ...cycleCount });
      dispatch(
        getProductsByFilters({
          requestBody: {
            storeCode: store.storeCode,
            ...data,
          },
        })
      );
    }
  }, [
    brandCodes,
    brandList,
    commodityCodes,
    dispatch,
    filterSelected,
    selectedEpc,
    selectedMarketingStories,
    selectedMarketingThemes,
    selectedModel,
    selectedUpc,
    store?.storeCode,
  ]);

  const createPageTitle = (): string => {
    if (isSohProcess) {
      return t('filterYourSoh');
    }

    switch (filterSelected) {
      case 0:
        return `${t('cycleCount.of')} ${selectedBrands
          .map(({ brandDescription }) => `#${brandDescription}`)
          .join(', ')}`;

      case 1:
        return `${t('cycleCount.of')} #UPC ${selectedUpc.join(', ')}`;

      case 2:
        return `${t('cycleCount.of')} #EPC ${selectedEpc.join(', ')}`;

      default:
        const selectedModels = [
          ...new Set(selectedModel.map(upcModel => upcModel.split('|')[1])),
        ];

        return `${t('cycleCount.of')} #MODEL ${selectedModels.join(', ')}`;
    }
  };

  const handleBackClick = (): void => {
    switch (filterSelected) {
      case 0:
        dispatch(resetFindItems());

        history.replace(
          brandsMarketingStories.length > 0 || brandsMarketingThemes.length > 0
            ? isSohProcess
              ? AppRoutes.SOH_ALIGNMENT_FILTER_BRAND
              : AppRoutes.CYCLE_COUNT_FILTER_MARKETING
            : isSohProcess
            ? AppRoutes.SOH_ALIGNMENT_FILTER_BRAND
            : AppRoutes.CYCLE_COUNT_FILTER_BRAND
        );
        return;

      case 1:
        return history.push(
          isSohProcess
            ? AppRoutes.SOH_ALIGNMENT_FILTER_UPC
            : AppRoutes.CYCLE_COUNT_FILTER_UPC
        );

      case 2:
        return history.push(AppRoutes.CYCLE_COUNT_FILTER_EPC);

      default:
        return history.push(AppRoutes.CYCLE_COUNT_FILTER_MODEL);
    }
  };

  const onStartReader = async (): Promise<void> => {
    if (isSohProcess) {
      try {
        setErrorStartSoh(false);

        const uniqueUpcList = [
          ...new Set(
            [...notFound, ...missing, ...checkoutErrorList].map(
              ({ upcCode }) => upcCode
            )
          ),
        ];

        let upcs = [];

        for (const upcCode of uniqueUpcList) {
          let preQty = 0;
          const inStock = notFound.find(p => p.upcCode === upcCode);

          if (inStock) {
            preQty = inStock.epcCodes.length;
          }

          upcs.push({ upcCode, preQty });
        }

        const body: StartSohAlighmentRequest = {
          storeCode: store?.storeCode || '',
          upcs,
          filter: {
            upcList: selectedUpc,
            brandList: brandCodes,
            commodityList: filterSelected !== 1 ? commodityCodes : [],
            filterByCommodityNull: commodityState.all && filterSelected !== 1,
          },
        };

        const response =
          await StockOnHandAlignmentService.stockonhandStartStockOnHandEncoded({
            requestBody: {
              base64: btoa(JSON.stringify(body)),
            },
          });

        if (response && response.stockOnHandID) {
          history.push(
            `${AppRoutes.SOH_ALIGNMENT_FILTER_PRODUCTS}?id=${response.stockOnHandID}`
          );
        }
      } catch {
        setErrorStartSoh(true);
      }
    } else {
      history.push(AppRoutes.CYCLE_COUNT_FILTER_PRODUCTS);
    }
  };

  const onScanClick = (): void => {
    if (isSohProcess) {
      if (notFound.length === 0 && missing.length === 0) {
        return setNoItemsModalVisibility(true);
      }
    }

    return setOpenModalScanVisbility(true);
  };

  if (productsIsLoading === true) {
    return <PageLoader />;
  }

  return (
    <>
      <Modal
        $minWidth="500px"
        $minHeight="250px"
        title={t('payAttention')}
        message={t('noItemsInSearchBasket')}
        open={noItemsModalIsVisible}
        onClose={(): void => history.push(AppRoutes.SOH_ALIGNMENT_FILTER_BRAND)}
      />
      <ModalScanDetailsV2
        resetDeviceInUse
        open={openModalScan}
        onClose={(): void => setOpenModalScanVisbility(false)}
        onStartReader={onStartReader}
      />
      <ModalProductDetails
        open={openProductModal}
        onClose={(): void => setProductModalVisibility(false)}
        productCode={selectedProduct}
      />
      <StyledPageContainer>
        <StyledTitle font="heavy" size="lg">
          {createPageTitle()}
        </StyledTitle>

        {notFound.length > 0 && (
          <UIList
            rounded
            backgrounded
            shadowed
            margin={[0, 0, 120]}
            itemsCount={
              <Trans
                i18nKey="cycleCount.expecteditems"
                values={{ notFoundItemsCount: expectedItems }}
              />
            }
          >
            <PaginationList
              data={notFound}
              infiniteScroll={true}
              pageSize={6}
              renderItem={(
                productItem: NonNullable<CycleDetailsItems>,
                i: number
              ): JSX.Element => (
                <ScannedProductItemCycleCount
                  productItem={productItem}
                  $v2={productItem.epcCodes.length > 1}
                  disableActions={true}
                  key={`${productItem.modelCode}-${i}-notFound`}
                  onClick={(e: React.MouseEvent): void => {
                    onProductClick(e, productItem.upcCode!);
                  }}
                />
              )}
            />
          </UIList>
        )}

        <CTAContainer
          type="CYCLECOUNT SCAN NOW"
          onClick={onScanClick}
          onBackClick={handleBackClick}
        />
      </StyledPageContainer>
      <ErrorSnackbar
        open={errorStartSoh}
        setIsOpen={setErrorStartSoh}
        errorMessage={t('error.startSoh')}
      />
    </>
  );
};

PageCycleCountFilterSummary.displayName = 'PageCycleCountFilterSummary';

export default PageCycleCountFilterSummary;
