import { combineReducers } from '@reduxjs/toolkit';
import breadcrumbs from '@/features/breadcrumbs/breadcrumbsSlice';
import currentStore from '@/features/stores/currentStoreSlice';
import deliveries from '@/features/deliveries/deliveriesSlice';
import deliveryProducts from '@/features/products/deliveryProductsSlice';
import confirmDeliveryProductsSlice from '@/features/products/confirmDeliveryProductsSlice';
import devices from '@/features/devices/devicesSlice';
import print from '@/features/print/printSlice';
import productDetails from '@/features/products/productDetailsSlice';
import saleProducts from '@/features/sales/salesProductsSlice';
import scannedProducts from '@/features/products/scannedProductsSlice';
import cycleCount from '@/features/cycleCount/cycleCountSlice';
import poc from '@/features/poc/pocSlice';
import stockSummary from '@/features/stockSummary/stockSummarySlice';
import s2s from '@/features/s2s/storeToStoreSlice';
import reprice from '@/features/repricing/repriceSlice';
import user from '@/features/user/userSlice';
import rolesManagement from '@/features/profiling/rolesSlice';
import storesManagement from '@/features/profiling/storesSlice';
import profilingUser from '@/features/profiling/usersSlice';
import profilingHdaUser from '@/features/profiling/hdaUsersSlice';
import profilingReasonCode from '@/features/profiling/reasonCodeSlice';
import storeDamages from '@/features/storeDamages/storeDamagesSlice';
import recall from '@/features/recall/recallSlice';
import inventory from '@/features/inventory/inventorySlice';
import manageDeliveries from '@/features/deliveriesManage/deliveriesManageSlice';
import maintenance from '@/features/maintenance/maintenanceSlice';
import pos from '@/features/pos/posSlice';
import returnPos from '@/features/returnPos/returnPosSlice';
import loader from '@/features/loader/loaderSlice';
import bis from '@/features/bis/backInStockSlice';
import processStatus from '@/features/processStatus/processStatusSlice';
import expiringSession from '@/features/expiringSession/expiringSession';
import sessionExpired from '@/features/expiringSession/sessionExpired';

const profiling = combineReducers({
  user: profilingUser,
  hdaUser: profilingHdaUser,
  rolesManagement,
  stores: storesManagement,
  reasonCode: profilingReasonCode,
});

const rootReducer = combineReducers({
  breadcrumbs,
  currentStore,
  deliveries,
  deliveryProducts,
  devices,
  print,
  productDetails,
  saleProducts,
  scannedProducts,
  user,
  confirmDeliveryProductsSlice,
  cycleCount,
  poc,
  stockSummary,
  s2s,
  reprice,
  profiling,
  storeDamages,
  recall,
  inventory,
  manageDeliveries,
  maintenance,
  pos,
  returnPos,
  loader,
  bis,
  processStatus,
  expiringSession,
  sessionExpired
});

export type RootState = ReturnType<typeof rootReducer>;
export type ProfilingState = ReturnType<typeof profiling>;

export default rootReducer;
