import { CycleDetailsItems } from '@/api';
import { useAppDispatch } from '@/app/store';
import { UIBox } from '@/components/ui/Box';
import { UIButton } from '@/components/ui/Button';
import { UICheckbox } from '@/components/ui/Checkbox';
import { StyledModalProps, UIModal } from '@/components/ui/Modal';
import { Typography } from '@/components/ui/Typography';
import { setDecodedItemsConfirmed } from '@/features/cycleCount/cycleCountSlice';
import { useSelector } from '@/hooks/useSelector';
import DecodedProductList from '@/pages/CycleCount/PageScanProducts/DecodedProductList';

import { palette } from '@/theme';
import { Product } from '@/types/enum';
import { IconButton } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import React, { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export interface DecodedModalProps {
  open: boolean;
  title?: string;
  subtitle?: string;
  message?: string;
  decodedItems?: [];
  onConfirm: () => void;
  onClose: () => void;
}

//#region - Styled Components

const StyledWrapper = styled(UIBox)`
  width: 100%;
  padding: 24px;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;

  flex-direction: column;
`;

const StyledCloseIcon = styled(IconButton)`
  align-self: baseline;

  svg {
    fill: ${palette.colors.primary};
  }
`;

const StyledConfirmModalContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledConfirmModalTextWrapper = styled(UIBox)`
  flex-direction: column;

  h1 {
    margin: 0 0 8px;
  }
`;

const StyledErrorOutlineIcon = styled(ErrorIcon)`
  margin-right: 8px;
  height: 24px;
  width: 24px;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
`;
const Content = styled.div`
  height: 100%;
  width: 100%;

  //max-height: 450px;
  overflow-y: auto;
`;
const Footer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const CheckConfirmContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-top: 16px;
`;
//#endregion

export const DecodedModal: FC<DecodedModalProps & Partial<StyledModalProps>> =
  memo(
    ({
      onClose,
      onConfirm,
      open,
      title,
      subtitle,
      ...styledProps
    }): JSX.Element => {
      const { t } = useTranslation();
      const dispatch = useAppDispatch();

      const [checkConfirm, setCheckConfirm] = useState<boolean>(false);

      const { decodedItems } = useSelector(state => state.cycleCount.products);

      useEffect(() => {
        const items: CycleDetailsItems[] = [];
        decodedItems?.forEach(item => {
          items.push(item);
        });
        dispatch(setDecodedItemsConfirmed({ decodedItemsConfirmed: items }));
      }, [dispatch, decodedItems]);

      return (
        <UIModal
          open={open}
          $width="90vw"
          $height="90vh"
          onClose={onClose}
          {...styledProps}
        >
          <StyledWrapper>
            <Header>
              <StyledConfirmModalContent>
                <StyledConfirmModalTextWrapper>
                  <Typography size="lg" font="heavy" as="h1">
                    {title}
                  </Typography>
                  <Typography
                    size="sm"
                    font="light"
                    as="h3"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <StyledErrorOutlineIcon htmlColor={palette.colors.yellow} />
                    {subtitle}
                  </Typography>
                </StyledConfirmModalTextWrapper>
              </StyledConfirmModalContent>
              <StyledCloseIcon onClick={onClose}>
                <HighlightOffIcon />
              </StyledCloseIcon>
            </Header>
            <Content>
              {decodedItems && decodedItems?.length > 0 && (
                <DecodedProductList
                  type={Product.DECODED}
                  data={decodedItems}
                  count={decodedItems.length}
                  disableActions={false}
                />
              )}
            </Content>
            <Footer>
              <CheckConfirmContainer
                onClick={(): void => setCheckConfirm(!checkConfirm)}
              >
                <UICheckbox checked={checkConfirm} />
                <Typography size="lg" font="heavy" as="h1">
                  {t('decoding.modal.check')}
                </Typography>
              </CheckConfirmContainer>
              <UIButton
                onClick={onConfirm}
                label={t('confirm')}
                outlined={true}
                disabled={!checkConfirm}
              />
            </Footer>
          </StyledWrapper>
        </UIModal>
      );
    }
  );

DecodedModal.displayName = 'DecodedModal';

export default DecodedModal;
