import { HdaUserRequest } from '@/api/users';
import { AppRoutes } from '@/app/routers';
import { useAppDispatch } from '@/app/store';
import { CTAContainer } from '@/components/layout/CTAContainer';
import ProfilingHDAUsersList from '@/components/layout/ProfilingHDAUsersList/ProfilingHDAUsersList';
import SearchBarLight from '@/components/layout/SearchBar/SearchBarLight';

import { AlertSnackbar } from '@/components/ui/AlertSnackbar';
import { UIBox } from '@/components/ui/Box';
import { UILoader } from '@/components/ui/Loader';
import {
  fetchHdaUsers,
  initProfilingHdaUserState,
} from '@/features/profiling/hdaUsersSlice';

import { useBreadcrumbs } from '@/hooks/useBreadcrumbs';
import { useSelector } from '@/hooks/useSelector';
import { HDAUsersFilters } from '@/types/filters';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useMount } from 'react-use';
import styled from 'styled-components';

import { StyledFilterWrapper, StyledNoResults } from './style';

const StyledLoaderWrapper = styled(UIBox)`
  margin: 100px 0;
  align-items: center;
  justify-content: center;
`;

const PageControlHDAUsers: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const {
    list: usersList,
    fetchHdaUsersIsLoading,
    fetchHdaUsersHasError,
    removeHdaUserHasError,
  } = useSelector(state => state.profiling.hdaUser);
  const [list, setList] = useState<HdaUserRequest[]>(usersList);

  useMount(async (): Promise<void> => {
    await dispatch(initProfilingHdaUserState());
  });

  const [alertSnackbarIsVisible, setAlertSnackbarVisibility] =
    useState<boolean>(false);

  const initialFilters: HDAUsersFilters = {
    userId: '',
    surname: '',
  };
  const [filters, setFilters] = useState<HDAUsersFilters>(initialFilters);

  useEffect(() => {
    if (fetchHdaUsersHasError) {
      setAlertSnackbarVisibility(true);
    }
  }, [fetchHdaUsersHasError]);

  const handleHDAUsers = useCallback(async (): Promise<void> => {
    dispatch(fetchHdaUsers());
  }, [dispatch]);

  useEffect(() => {
    handleHDAUsers();
  }, [handleHDAUsers]);

  useEffect(() => {
    const list = usersList.filter(
      user =>
        user.userId?.toLowerCase().includes(filters.userId.toLowerCase()) &&
        user.surname?.toLowerCase().includes(filters.surname.toLowerCase())
    );
    setList(list);
  }, [filters, usersList]);

  useBreadcrumbs([{ title: t('page.controlPanel') }]);

  const onFilterChange = (
    key: keyof HDAUsersFilters,
    value: string | boolean
  ): void => {
    setFilters(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const onBackClick = (): void => {
    history.push(AppRoutes.ADMIN);
  };

  const onAddUser = (): void => {
    history.push(AppRoutes.MANAGE_HDA_USERS_CREATE);
  };

  return (
    <>
      <StyledFilterWrapper>
        <SearchBarLight
          label=""
          value={filters.userId}
          placeholder={t('profiling.searchById')}
          disabled={false}
          onSearch={(): void => {}}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            onFilterChange('userId', e.target.value)
          }
          loading={false}
          hideButton={true}
          hideSearchIcon={true}
          inputProps={{
            maxLength: 255,
            minLength: 0,
          }}
        />

        <SearchBarLight
          label=""
          value={filters.surname}
          placeholder={t('profiling.searchBySurname')}
          disabled={false}
          onSearch={(): void => {}}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            onFilterChange('surname', e.target.value)
          }
          loading={false}
          hideButton={true}
          hideSearchIcon={true}
          inputProps={{
            maxLength: 255,
            minLength: 0,
          }}
        />
      </StyledFilterWrapper>

      {fetchHdaUsersIsLoading ? (
        <StyledLoaderWrapper>
          <UILoader />
        </StyledLoaderWrapper>
      ) : (
        <>
          {list.length > 0 ? (
            <ProfilingHDAUsersList
              users={list}
              setAlertSnackbarVisibility={setAlertSnackbarVisibility}
            />
          ) : (
            <StyledNoResults>
              <p className={'title'}>{t('controlUsers.noResult.title')}</p>
              <p>{t('controlUsers.noResult.message')}</p>
            </StyledNoResults>
          )}
        </>
      )}

      <CTAContainer
        type="ADD HDA USER"
        onClick={(): void => onAddUser()}
        onBackClick={(): void => onBackClick()}
      />

      <AlertSnackbar
        open={alertSnackbarIsVisible && !!removeHdaUserHasError}
        setIsOpen={setAlertSnackbarVisibility}
        message={removeHdaUserHasError?.body.errorMessage || 'Generic Error'}
      />

      <AlertSnackbar
        open={alertSnackbarIsVisible && !!fetchHdaUsersHasError}
        setIsOpen={setAlertSnackbarVisibility}
        message={fetchHdaUsersHasError?.body.errorMessage || 'Generic Error'}
      />
    </>
  );
};

export default PageControlHDAUsers;
