import styled from 'styled-components';
import { UIBox } from '@/components/ui/Box';

export const StyledFilterWrapper = styled(UIBox)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  padding: 24px;
  margin-bottom: 16px;

  & > * {
    margin: 0;
    padding: 0 !important;
    align-self: start;
  }
`;

export const StyledNoResults = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-weight: 800;
  }
`;
