import { SearchDecodedByFilter } from '@/api/receive';
import { CycleCountSlice } from '@/types/cycleCount';

export const buildCCFilters = ({
  brandList,
  selectedBrands,
  selectedUpc,
  selectedModel,
  filterSelected,
  selectedMarketingThemes,
  selectedMarketingStories,
  commodityState,
}: CycleCountSlice): SearchDecodedByFilter => {
  const brandCodes = selectedBrands.map(({ brandCode }) => brandCode!);

  let commodityCodes: string[];

  if (commodityState.all) {
    commodityCodes =
      brandList?.commodityList
        ?.map(({ commodityCode }) => commodityCode!)
        .filter(code => code !== 'ALL') || [];
  } else {
    commodityCodes =
      brandList?.commodityList
        ?.filter(({ commodityDescription }) => {
          if (
            commodityDescription &&
            commodityState.hasOwnProperty(commodityDescription.toLowerCase())
          ) {
            return commodityState[
              commodityDescription.toLowerCase() as keyof typeof commodityState
            ];
          }
          return false;
        })
        .map(({ commodityCode }) => commodityCode!) || [];
  }

  let data: SearchDecodedByFilter = {};

  const marketingThemeCodes = selectedMarketingThemes.map(
    ({ marketingThemeCode }) => marketingThemeCode!
  );

  const marketingStoryCodes = selectedMarketingStories.map(
    ({ marketingStoryCode }) => marketingStoryCode!
  );

  switch (filterSelected) {
    case 0:
      data = {
        brandCodes,
        marketingStoryCodes,
        marketingThemeCodes,
        commodityCodes,
      };
      break;
    case 1:
      data = { upcList: selectedUpc };
      break;
    case 3:
      data = {
        upcList: selectedModel.map(upcModel => upcModel.split('|')[0]),
      };
      break;
    default:
      break;
  }

  return data;
};
