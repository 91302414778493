import React, { useState } from 'react';
import { CycleDetailsItems, ProductItemForPrint } from '@/api/receive';

export type CheckedUpcs = {
  [key: string]: CycleDetailsItems;
};

export type CheckedEpcs = {
  [key: string]: string[];
};

interface ICycleCountContext {
  isNotFoundModalReasonVisibile: boolean;
  setNotFoundModalReasonVisibility: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  isUnexpectedModalReasonVisibile: boolean;
  setUnexpectedModalReasonVisibility: React.Dispatch<
    React.SetStateAction<boolean>
  >;

  checkedNotFoundUpcs: CheckedUpcs;
  checkedNotFoundEpcs: CheckedEpcs;
  checkedNotFoundUpcsToUndo: CheckedUpcs;
  checkedNotFoundEpcsToUndo: CheckedEpcs;

  checkedUnexpectedUpcs: CheckedUpcs;
  checkedUnexpectedUpcsToUndo: CheckedUpcs;
  checkedUnexpectedEpcs: CheckedEpcs;
  checkedUnexpectedEpcsToUndo: CheckedEpcs;

  setCheckedNotFoundUpcs: React.Dispatch<React.SetStateAction<CheckedUpcs>>;
  setCheckedNotFoundUpcsToUndo: React.Dispatch<
    React.SetStateAction<CheckedUpcs>
  >;
  setCheckedNotFoundEpcs: React.Dispatch<React.SetStateAction<CheckedEpcs>>;
  setCheckedNotFoundEpcsToUndo: React.Dispatch<
    React.SetStateAction<CheckedEpcs>
  >;

  setCheckedUnexpectedUpcs: React.Dispatch<React.SetStateAction<CheckedUpcs>>;
  setCheckedUnexpectedUpcsToUndo: React.Dispatch<
    React.SetStateAction<CheckedUpcs>
  >;
  setCheckedUnexpectedEpcs: React.Dispatch<React.SetStateAction<CheckedEpcs>>;
  setCheckedUnexpectedEpcsToUndo: React.Dispatch<
    React.SetStateAction<CheckedEpcs>
  >;

  checkedDecodedEpcs: CheckedEpcs;
  setCheckedDecodedEpcs: React.Dispatch<React.SetStateAction<CheckedEpcs>>;

  notFoundEpcsWithReason: string[];
  setNotFoundEpcsWithReason: React.Dispatch<React.SetStateAction<string[]>>;
  missingItemsBrands: string[];
  setMissingItemsBrands: React.Dispatch<React.SetStateAction<string[]>>;
  openPrintModal: boolean;
  openPrintNoRfidModal: boolean;
  openProductModal: boolean;
  undoSnackbarIsVisible: boolean;
  setPrintModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  setPrintNoRfidModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  setProductModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  setUndoSnackbarVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  productToPrint?: ProductItemForPrint;
  setProductToPrint: React.Dispatch<
    React.SetStateAction<ProductItemForPrint | undefined>
  >;
  oldEpc: string;
  setOldEpc: React.Dispatch<React.SetStateAction<string>>;
  productDetailsByUpc: string;
  setProductDetailsByUpc: React.Dispatch<React.SetStateAction<string>>;
}

const CycleCountContext = React.createContext<ICycleCountContext>({
  isNotFoundModalReasonVisibile: false,
  setNotFoundModalReasonVisibility: () => {},
  isUnexpectedModalReasonVisibile: false,
  setUnexpectedModalReasonVisibility: () => {},
  checkedNotFoundUpcs: {},
  checkedNotFoundEpcs: {},
  checkedUnexpectedUpcs: {},
  checkedUnexpectedEpcs: {},
  checkedDecodedEpcs: {},
  setCheckedNotFoundUpcs: () => {},
  setCheckedNotFoundEpcs: () => {},
  setCheckedUnexpectedUpcs: () => {},
  setCheckedUnexpectedEpcs: () => {},
  setCheckedDecodedEpcs: () => {},
  notFoundEpcsWithReason: [],
  setNotFoundEpcsWithReason: () => {},
  missingItemsBrands: [],
  setMissingItemsBrands: () => {},
  productToPrint: undefined,
  setProductToPrint: () => {},
  openPrintModal: false,
  openPrintNoRfidModal: false,
  openProductModal: false,
  setPrintModalVisibility: () => {},
  setPrintNoRfidModalVisibility: () => {},
  setProductModalVisibility: () => {},
  oldEpc: '',
  setOldEpc: () => {},
  productDetailsByUpc: '',
  setProductDetailsByUpc: () => {},
  undoSnackbarIsVisible: false,
  setUndoSnackbarVisibility: () => {},
  checkedNotFoundUpcsToUndo: {},
  checkedNotFoundEpcsToUndo: {},
  checkedUnexpectedUpcsToUndo: {},
  checkedUnexpectedEpcsToUndo: {},
  setCheckedNotFoundUpcsToUndo: () => {},
  setCheckedNotFoundEpcsToUndo: () => {},
  setCheckedUnexpectedUpcsToUndo: () => {},
  setCheckedUnexpectedEpcsToUndo: () => {},
});

export const CycleCountContextProvider: React.FC = ({ children }) => {
  const [productToPrint, setProductToPrint] = useState<ProductItemForPrint>();
  const [productDetailsByUpc, setProductDetailsByUpc] = useState<string>('');
  const [openPrintModal, setPrintModalVisibility] = useState<boolean>(false);
  const [oldEpc, setOldEpc] = useState<string>('');
  const [openPrintNoRfidModal, setPrintNoRfidModalVisibility] =
    useState<boolean>(false);
  const [openProductModal, setProductModalVisibility] =
    useState<boolean>(false);
  const [undoSnackbarIsVisible, setUndoSnackbarVisibility] =
    useState<boolean>(false);

  // * UNDO SELECTED ITEMS STATE
  const [checkedNotFoundUpcsToUndo, setCheckedNotFoundUpcsToUndo] =
    useState<CheckedUpcs>({});

  const [checkedNotFoundEpcsToUndo, setCheckedNotFoundEpcsToUndo] =
    useState<CheckedEpcs>({});

  const [checkedUnexpectedUpcsToUndo, setCheckedUnexpectedUpcsToUndo] =
    useState<CheckedUpcs>({});

  const [checkedUnexpectedEpcsToUndo, setCheckedUnexpectedEpcsToUndo] =
    useState<CheckedEpcs>({});

  // * SELECTED ITEMS STATE
  const [checkedNotFoundUpcs, setCheckedNotFoundUpcs] = useState<CheckedUpcs>(
    {}
  );
  const [checkedNotFoundEpcs, setCheckedNotFoundEpcs] = useState<CheckedEpcs>(
    {}
  );

  const [checkedUnexpectedUpcs, setCheckedUnexpectedUpcs] =
    useState<CheckedUpcs>({});

  const [checkedUnexpectedEpcs, setCheckedUnexpectedEpcs] =
    useState<CheckedEpcs>({});

  const [checkedDecodedEpcs, setCheckedDecodedEpcs] =
    useState<CheckedEpcs>({});

  const [isNotFoundModalReasonVisibile, setNotFoundModalReasonVisibility] =
    useState<boolean>(false);

  const [isUnexpectedModalReasonVisibile, setUnexpectedModalReasonVisibility] =
    useState<boolean>(false);

  const [notFoundEpcsWithReason, setNotFoundEpcsWithReason] = useState<
    string[]
  >([]);

  const [missingItemsBrands, setMissingItemsBrands] = useState<string[]>([]);

  return (
    <CycleCountContext.Provider
      value={{
        openPrintModal,
        openPrintNoRfidModal,

        setPrintModalVisibility,
        setPrintNoRfidModalVisibility,

        productToPrint,
        setProductToPrint,

        checkedNotFoundUpcs,
        checkedNotFoundUpcsToUndo,
        checkedNotFoundEpcs,
        checkedNotFoundEpcsToUndo,

        checkedUnexpectedUpcs,
        checkedUnexpectedUpcsToUndo,
        checkedUnexpectedEpcs,
        checkedUnexpectedEpcsToUndo,

        setCheckedNotFoundEpcs,
        setCheckedNotFoundEpcsToUndo,
        setCheckedNotFoundUpcs,
        setCheckedNotFoundUpcsToUndo,

        setCheckedUnexpectedUpcs,
        setCheckedUnexpectedUpcsToUndo,
        setCheckedUnexpectedEpcs,
        setCheckedUnexpectedEpcsToUndo,

        checkedDecodedEpcs,
        setCheckedDecodedEpcs,

        isNotFoundModalReasonVisibile,
        setNotFoundModalReasonVisibility,

        isUnexpectedModalReasonVisibile,
        setUnexpectedModalReasonVisibility,

        notFoundEpcsWithReason,
        setNotFoundEpcsWithReason,

        oldEpc,
        setOldEpc,

        productDetailsByUpc,
        setProductDetailsByUpc,

        openProductModal,
        setProductModalVisibility,

        undoSnackbarIsVisible,
        setUndoSnackbarVisibility,

        missingItemsBrands,
        setMissingItemsBrands,
      }}
    >
      {children}
    </CycleCountContext.Provider>
  );
};

export const useCycleCountContext = (): ICycleCountContext =>
  React.useContext(CycleCountContext);
