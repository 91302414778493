import { HdaUserRequest } from '@/api/users';
import { AppRoutes } from '@/app/routers';
import { useAppDispatch } from '@/app/store';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { Form } from '@/components/layout/Form';
import { ModalAttention } from '@/components/layout/ModalAttention';
import { Tabs } from '@/components/layout/Tabs';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';
import { UIBox } from '@/components/ui/Box';
import SelectController from '@/components/ui/Select/SelectController';
import { UISwitch } from '@/components/ui/Switch';

import TextFieldController from '@/components/ui/TextField/TextFieldController';
import { upsertHdaUser } from '@/features/profiling/hdaUsersSlice';

import { getUserDetails, initProfilingUserState } from '@/features/profiling/usersSlice';

import { useSelector } from '@/hooks/useSelector';
import { FormInputs } from '@/types/hookFormInput';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useAsync } from 'react-use';
import styled from 'styled-components';

//#region - Styled Components
const StyledSwitchWrapper = styled(UIBox)`
  width: 100%;
  margin: 12px 32px 0 0;

  & > * {
    margin-left: auto;
  }
`;

//#endregion

const PageControlHDAUsersCreate: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const tabs = [t('personalData')];

  const {
    upsertHdaUserIsLoading,
    upsertHdaUserHasError,
  } = useSelector(state => state.profiling.hdaUser);

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const [alertSnackbarIsVisible, setAlertSnackbarVisibility] =
    useState<boolean>(false);

  const [activeUser, setActiveUser] = useState<boolean>(true);

  const [attentionModalIsVisible, setAttentionModalVisibility] =
    useState<boolean>(false);

  const { handleSubmit, control, watch, reset, getValues } =
    useForm<FormInputs>({
      mode: 'onChange',
      defaultValues: {
        userId: '',
        firstName: '',
        lastName: '',
        email: '',
        language: '',
      },
    });

  const isEmailFormatValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
    getValues('email')
  );

  const isFormValid =
    getValues('userId') !== '' &&
    getValues('firstName') !== '' &&
    getValues('lastName') !== '' &&
    getValues('language') !== '';

  const confirmDisabled =
    !isFormValid || (getValues('email') !== '' && !isEmailFormatValid);

  useAsync(async () => {
    await dispatch(getUserDetails());
  }, []);

  const onFormSubmit: SubmitHandler<FormInputs> = async (
    formData
  ): Promise<void> => {
    const { userId, language, firstName, lastName, email } = formData;
    try {
      const createUserBody: HdaUserRequest = {
        userId: userId.toUpperCase(),
        language,
        surname: lastName,
        name: firstName,
        email,
        statusUser: activeUser ? 'ACTIVE' : 'INACTIVE',
      };

      await dispatch(upsertHdaUser({ userId: createUserBody.userId, requestBody: createUserBody })).unwrap();
      await reset();
      await dispatch(initProfilingUserState());

      history.push(AppRoutes.MANAGE_HDA_USERS);
    } catch {
      setAlertSnackbarVisibility(true);
    }
  };

  const onSaveClick = async (): Promise<void> => {
    await handleSubmit(onFormSubmit)();
  };

  const onConfirmBackModalClick = async (): Promise<void> => {
    await onSaveClick();
    setAttentionModalVisibility(false);
    history.push(AppRoutes.MANAGE_HDA_USERS);
  };

  const onUnConfirmModalClick = async (): Promise<void> => {
    setAttentionModalVisibility(false);
    history.push(AppRoutes.MANAGE_HDA_USERS);
  };

  const onCloseBackModalClick = (): void => {
    setAttentionModalVisibility(false);
  };

  const onBackClick = (): void => {
    if (isFormValid) {
      return setAttentionModalVisibility(true);
    }

    history.push(AppRoutes.MANAGE_HDA_USERS);
  };

  return (
    <>
      <ModalAttention
        open={attentionModalIsVisible}
        onConfirmClick={onConfirmBackModalClick}
        onUnconfirmClick={onUnConfirmModalClick}
        onClose={onCloseBackModalClick}
        message={t('modal.attention.messageSave')}
        disableConfirm={confirmDisabled}
      />
      <Tabs
        mt="18px"
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        values={tabs}
      />
      <>
        <StyledSwitchWrapper>
          <UISwitch
            checked={activeUser}
            checkedLabel={t('activeUser')}
            unCheckedLabel={t('inactiveUser')}
            onChange={(): void => setActiveUser(prevState => !prevState)}
          />
        </StyledSwitchWrapper>
        <Form>
          <TextFieldController
            required
            uppercase
            name="userId"
            value={watch('userId')}
            control={control}
            isErrorVisible={getValues('userId') === ''}
          />
          <TextFieldController
            required
            name="firstName"
            value={watch('firstName')}
            control={control}
            isErrorVisible={getValues('firstName') === ''}
          />
          <TextFieldController
            required
            name="lastName"
            value={watch('lastName')}
            control={control}
            isErrorVisible={getValues('lastName') === ''}
          />
          <TextFieldController
            required
            name="email"
            value={watch('email')}
            control={control}
            isErrorVisible={getValues('email') !== '' && !isEmailFormatValid}
          />
          <SelectController
            required
            name="language"
            control={control}
            options={(i18n.languages || []) as string[]}
            value={watch('language')}
            isErrorVisible={getValues('language') === ''}
          />
        </Form>
      </>
      <CTAContainer
        type="SAVE"
        disabledMainAction={confirmDisabled}
        disabled={upsertHdaUserIsLoading}
        loading={upsertHdaUserIsLoading}
        onClick={(): Promise<void> => onSaveClick()}
        onBackClick={onBackClick}
      />
      <AlertSnackbar
        open={alertSnackbarIsVisible}
        setIsOpen={setAlertSnackbarVisibility}
        message={upsertHdaUserHasError?.body}
      />
    </>
  );
};

export default PageControlHDAUsersCreate;
