import type { CycleDetailsItems } from '@/api';
import { PaginationList } from '@/components/layout/PaginationList';
import { useSelector } from '@/hooks/useSelector';
import { Product } from '@/types/enum';
import React from 'react';
import DecodedProductListItem from '../DecodedProductListItem';
import type { ProductListProps, ProductListType } from '../types';
import { StyledUIList, StyledUIListWrapper } from './style';

const DecodedProductList: React.FC<ProductListProps & ProductListType> = ({
  data,
  type,
  disableActions,
}) => {
  const { isMissingItemsCycleCount } = useSelector(state => state.cycleCount);

  return (
    <StyledUIListWrapper
      className={type}
      order={isMissingItemsCycleCount && type === Product.FOUND ? '1' : '2'}
    >
      <StyledUIList className={type} rounded backgrounded shadowed>
        <PaginationList
          data={data}
          pageSize={25}
          renderItem={(
            productItem: NonNullable<CycleDetailsItems>,
            i: number
          ): JSX.Element => (
            <React.Fragment key={i}>
              <DecodedProductListItem
                key={`${productItem.upcCode}-${i}`}
                type={type}
                disableActions={disableActions}
                {...productItem}
              />
            </React.Fragment>
          )}
        />
      </StyledUIList>
    </StyledUIListWrapper>
  );
};

export default DecodedProductList;
