import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { palette } from '@/theme';
import { Chip } from '@material-ui/core';
import styled from 'styled-components';

export const StyledAccordionWrapper = styled(UIBox)`
  margin-left: -48px;
  margin-right: 24px;
`;

export const StyledCycleCountItemWrapper = styled(UIBox)`
  width: 100%;
  padding: 12px 32px;
  margin: 18px 0;
  border-radius: 4px;
  background: ${palette.colors.midGrey};
  border: 1px solid #dedede; // ? Add color in palette object
  flex-direction: column;
  justify-content: start;
  gap: 24px 0;

  &:first-child {
    margin-top: 24px;
  }

  @media screen and (min-width: 768px) {
    flex-direction: column;
    padding: 24px 66px;
  }
`;

export const StyledCycleCountItemInfo = styled(UIBox)`
  flex-direction: column;
  width: max-content;
  svg {
    fill: ${palette.colors.grey};
    height: 12px;
  }
`;

export const StyledCycleCountItemBrandInfo = styled(UIBox)<{
  $accordion?: boolean;
}>`
  flex-direction: column;

  @media screen and (min-width: 768px) {
    margin-left: ${({ $accordion }): string =>
      $accordion ? '107px' : '116px'};
  }

  ${({ theme, $accordion }): string =>
    `${theme.breakpoints.down('sm')} {
      @media screen and (min-width: 768px) {
        margin-left: ${$accordion ? '57px' : '66px'};
      }
    }`}
`;

export const StyledCycleCountItemDate = styled(UIBox)`
  align-items: center;
  text-transform: capitalize;
`;

export const StyledCycleCountChip = styled(Chip)`
  && {
    background: #707173; // ? Add color in palette object
    color: ${palette.colors.white};
    min-width: 85px;
    font-size: 12px;
    margin-top: 12px;
  }
`;

export const StyledCycleCountItemBrand = styled(UIBox)`
  flex-direction: column;
`;

export const StyledBrand = styled(Typography)`
  text-transform: uppercase;
  letter-spacing: 0.04em;
  margin-bottom: 8px;
`;

export const StyledStatus = styled(Typography)`
  text-transform: capitalize;
  display: flex;
  height: min-content;
  align-items: center;
  gap: 8px;
  color: #4b4b4b;
  @media screen and (min-width: 768px) {
    margin-left: auto;
  }
`;

export const StyledAccordionContent = styled(UIBox)<{ ml: string }>`
  flex-direction: column;
  padding: 18px 0 24px 32px;
  margin-left: ${({ ml }): string => ml};
`;

export const StyledAccordionItemContent = styled(UIBox)<{
  $missingCC?: boolean;
}>`
  width: 100%;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    margin-left: 240px;
  }

  ${({ theme, $missingCC }): string =>
    `${theme.breakpoints.down('sm')} {
      @media screen and (min-width: 768px) {
				margin-left: ${$missingCC ? '162px' : '192px'} ;
      }
    }`}
`;

export const StyledPreviouslyContainer = styled.div`
  display: flex;
  gap: 24px;
`;
