import { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { UIMacroFlowMenu } from '@/components/layout/MacroFlowMenu';
import { UIButton } from '@/components/ui/Button';

import { AppRoutes } from '@/app/routers';
import {
  isUserHda,
  isReasonsManager,
  isStoresManager,
  isUsersManager,
} from '@/utils/user';
import { useSelector } from '@/hooks/useSelector';

const StyledLink = styled(Link)<{ disabled?: boolean }>`
  pointer-events: ${({ disabled }): string => (disabled ? 'none' : 'auto')};
`;

const PageControlAdmin: FC = () => {
  const { t } = useTranslation();
  const user = useSelector(state => state.user);

  return (
    <UIMacroFlowMenu title={t('menu.title')}>
      {(isUsersManager(user) || isUserHda(user)) && (
        <StyledLink to={AppRoutes.MANAGE_USERS}>
          <UIButton label={t('menu.users')} outlined={false} disabled={false} />
        </StyledLink>
      )}
      {(isUsersManager(user) || isUserHda(user)) && (
        <StyledLink to={AppRoutes.MANAGE_HDA_USERS}>
          <UIButton
            label={t('menu.HDAUsers')}
            outlined={false}
            disabled={false}
          />
        </StyledLink>
      )}
      {isStoresManager(user) && !isUserHda(user) && (
        <StyledLink to={AppRoutes.MANAGE_STORES}>
          <UIButton
            label={t('menu.stores')}
            outlined={false}
            disabled={false}
          />
        </StyledLink>
      )}
      {isReasonsManager(user) && !isUserHda(user) && (
        <StyledLink to={AppRoutes.MANAGE_REASON_CODES} disabled={false}>
          <UIButton
            label={t('menu.manageReasonCodes')}
            outlined={false}
            disabled={false}
          />
        </StyledLink>
      )}
    </UIMacroFlowMenu>
  );
};

export default PageControlAdmin;
