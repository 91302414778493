import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { theme } from '@/theme';

import styled, { css } from 'styled-components';

export const GridStyles = css<{
  $showPartial?: boolean;
  $isUserAdmin?: boolean;
}>`
  display: grid;
  align-items: center;
  gap: 16px;

  grid-template-columns: ${({ $showPartial, $isUserAdmin }): string =>
    $isUserAdmin
      ? $showPartial
        ? 'repeat(5, 15%) auto'
        : '40px repeat(4, 10%) 500px 10% auto'
      : $showPartial
      ? 'repeat(5, 15%) auto'
      : 'repeat(4, 10%) 500px 10% auto'};

  ${theme.breakpoints.down('md')} {
    grid-template-columns: ${({ $showPartial, $isUserAdmin }): string =>
      $isUserAdmin
        ? $showPartial
          ? 'repeat(5, 15%) auto'
          : '40px repeat(4, 10%) 250px 10% auto'
        : $showPartial
        ? 'repeat(5, 15%) auto'
        : 'repeat(4, 10%) 250px 10% auto'};
  }
`;

export const StyledGrid = styled(UIBox)`
  width: 100%;
  flex-direction: column;
  margin-bottom: 120px;

  & > * {
    padding: 0;
  }
`;

export const StyledGridHeader = styled(UIBox)<{
  $showPartial?: boolean;
  $isUserAdmin: boolean;
}>`
  width: 100%;
  padding: 8px 24px;
  border-bottom: 1px solid #4b4b4b;
  ${GridStyles}
`;

export const StyledRowWrapper = styled(UIBox)`
  flex-direction: column;

  &:not(:nth-of-type(odd)) {
    background: #fbfbfb;
  }
`;

export const StyledGridRow = styled(UIBox)`
  width: 100%;
  padding: 11px 24px;
`;

export const StyledGridRowContent = styled(UIBox)<{
  $showPartial?: boolean;
  $isUserAdmin?: boolean;
}>`
  width: 100%;
  padding: 8px 0;

  ${GridStyles}
  & > * {
    &:last-child {
      width: 100%;
      justify-self: flex-end;
      /* justify-content: ${({ $showPartial }): string =>
        $showPartial ? 'end' : 'start'};*/
      justify-content: end;
    }
  }
`;

export const StyledSpan = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledUserId = styled(UIBox)`
  gap: 8px;
  align-items: center;
`;

export const StyledRole = styled('span')`
  color: grey;
`;

export const StyledStatusWrapper = styled(UIBox)`
  align-items: center;
`;

export const StyledStatus = styled('div')<{ status?: string }>`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 6px;
  background: ${({ status }): string =>
    status?.toLowerCase() === 'active' ? 'green' : 'red'};
`;

export const StyledActionsWrapper = styled(UIBox)`
  align-items: center;

  & > * {
    &:not(:last-child) {
      margin-left: auto;
      margin-right: 12px;
    }
  }
`;

export const StyledAccordionItem = styled(UIBox)<{
  $isUserAdmin?: boolean;
  $showPartial?: boolean;
  $isUserPage?: boolean;
}>`
  ${GridStyles};
  padding: 0 24px 11px;
  flex-direction: column;
  row-gap: 0;

  & > span {
    grid-column-start: ${({ $isUserAdmin, $isUserPage }): number =>
      $isUserAdmin && $isUserPage ? 4 : 3};
    display: block;
    text-transform: capitalize;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

export const StyledActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  justify-self: flex-end;
  cursor: pointer;
`;
