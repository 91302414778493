import { HdaUserRequest } from '@/api/users';
import { PageLoader } from '@/components/ui/PageLoader';
import { fetchHdaUsers, upsertHdaUser } from '@/features/profiling/hdaUsersSlice';
import { memo, FC, useState, useEffect, ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from '@/hooks/useSelector';
import { useHistory } from 'react-router';

import { useBreadcrumbs } from '@/hooks/useBreadcrumbs';

import { Grid } from '@material-ui/core';
import { Place as PlaceIcon } from '@material-ui/icons';

import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { UISelect } from '@/components/ui/Select';
import { UIButton } from '@/components/ui/Button';

import { ReceiveShipmentsService } from '@/api';

import type { StoreWithPrinters } from '@/types/store';

import { setStore } from '@/features/stores/currentStoreSlice';
import { getAllDevicesStatus } from '@/features/devices/devicesSlice';

import { palette } from '@/theme/_colors';

import { SettingsService } from '@/api';
import { AppRoutes } from '@/app/routers';
import { useAppDispatch } from '@/app/store';
import { UIAutoCompleteStores } from '@/components/ui/AutoComplete';
import { useSignalRContext } from '@/context/signalR';
import { useSocketContext } from '@/context/socketContext';

//#region - Styled Components
const StyledTitleWrapper = styled(UIBox)`
  align-items: center;
  justify-content: center;
  margin: 44px 0;

  h1 {
    text-transform: uppercase;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 100px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 125px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 20px;
`;

const AutocompleteWrapper = styled(UIBox)`
  flex-direction: column;
  width: 573px;
  margin: 0 auto 47px;

  i {
    margin-bottom: 20px;
  }
`;

const ChangeStoreButton = styled(UIButton)`
  text-transform: capitalize;
  text-decoration: underline;
  width: fit-content;

  &:hover {
    text-decoration: underline;
  }
`;

const CurrentStoreInfoWrapper = styled.div`
  border-bottom: 1px solid ${palette.colors.grey};
`;

const CancelButton = styled(UIButton)`
  max-width: 190px;
`;
//#endregion

export const PageSettings: FC = memo((): JSX.Element => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const signalr = useSignalRContext();
  const { unsubscribeProcess, subscribeProcess } = useSocketContext();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    i18n.language
  );
  const loggedUser = useSelector(state => state.user);

  const { store: initialStore } = useSelector(state => state.currentStore);
  const { username, isHda } = useSelector(state => state.user);

  const {
    list: userDetailsArray,
    fetchHdaUsersIsLoading,
    upsertHdaUserIsLoading,
  } = useSelector(state => state.profiling.hdaUser);

  const userDetails: HdaUserRequest = useMemo(
    () => {
      return userDetailsArray && userDetailsArray[0] && {
        ...userDetailsArray[0],
        language: userDetailsArray[0].language?.toLowerCase() || ''
      }
    },
    [userDetailsArray]
  );

  const [confirmLoader, setConfirmLoader] = useState<boolean>(false);

  const [isChangingStore, setIsChangingStore] = useState<boolean>(false);

  const history = useHistory();

  const [selectedStore, setSelectedStore] = useState<StoreWithPrinters>();

  const changeStoreButtonHandler: React.MouseEventHandler<
    HTMLButtonElement
  > = () => {
    setIsChangingStore(true);
  };

  useEffect(() => {
    if (i18n.language) {
      setSelectedLanguage(i18n.language);
    }
  }, [i18n.language]);

  useEffect(() => {
    if (userDetails?.language) {
      setSelectedLanguage(userDetails.language)
    }
  }, [userDetails])

  useEffect(() => {
    if (initialStore && !loggedUser.isHda) {
      setSelectedStore(initialStore);
    }
  }, [initialStore]);

  useEffect(() => {
    if (loggedUser.isHda) {
      dispatch(fetchHdaUsers(loggedUser.username))
    }
  }, [dispatch, loggedUser]);

  useBreadcrumbs([
    {
      title: t('page.settings'),
    },
  ]);

  const onSelectLanguage = async (e: React.ChangeEvent<{ value: unknown }>): Promise<void> => {
    const language = e.target.value as string;
    await i18n.changeLanguage(language);
    setSelectedLanguage(language);
  };

  const confirmButtonHandler: React.MouseEventHandler<Element> = async () => {
    if (loggedUser.isHda) {
      try {
        const updateUserBody: { userId: string; requestBody: HdaUserRequest } = {
          userId: String(userDetails['userId']),
          requestBody: {
            ...userDetails,
            language: selectedLanguage,
          },
        };
        await dispatch(upsertHdaUser(updateUserBody)).unwrap();
        history.replace(AppRoutes.INTRO);
      } catch(e) {
        console.log(e);
      }
    } else if (selectedStore) {
      if (selectedStore.storeCode) {
        setConfirmLoader(true);
        const storeWithPrinters =
          await ReceiveShipmentsService.receiveshipReadStoreByStoreCode({
            storeCode: selectedStore.storeCode,
          });

        await SettingsService.usersettingsSetUserSettings({
          requestBody: {
            idUser: username!,
            // @ts-ignore
            languageIso: selectedLanguage.toUpperCase(),
            storeCode: selectedStore.storeCode!,
          },
        });

        await dispatch(setStore(storeWithPrinters as StoreWithPrinters));

        if (selectedStore?.storeCode) {
          await unsubscribeProcess(`/process/${initialStore?.storeCode!}`);
          await dispatch(getAllDevicesStatus());
          await subscribeProcess(`/process/${selectedStore?.storeCode}`);
        }

        await signalr.disconnect();

        setConfirmLoader(false);
        history.replace(AppRoutes.INTRO);
      }
    }
  };

  if (fetchHdaUsersIsLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <StyledTitleWrapper>
        <Typography as="h1" size="lg" font="book">
          {t('settings')}
        </Typography>
      </StyledTitleWrapper>

      <Wrapper>
        <UISelect
          title="settingApplicationLanguage"
          wrapperwidth="573px"
          variant="outlined"
          onChange={(e: React.ChangeEvent<{ value: unknown }>): Promise<void> =>
            onSelectLanguage(e)
          }
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          }}
          values={i18n.languages.map(lng => ({
            label: t(`languages.${lng}`),
            value: lng,
          }))}
          value={selectedLanguage}
          renderValue={(value): ReactNode => t(`languages.${value}`)}
        />

        {!isHda && (
          <AutocompleteWrapper>
            <Typography as="h1" font="heavy">
              {t('settingStoreWhereYouWorkIn')}
            </Typography>
            <Typography as="i">
              {t('settingStoreWhereYouWorkInHelp')}
            </Typography>

            <Grid
              container
              alignItems="center"
              alignContent="center"
              justify="center"
              spacing={1}
            >
              <Grid item xs={1}>
                <PlaceIcon />
              </Grid>
              <Grid item xs={isChangingStore ? 11 : 8}>
                {isChangingStore ? (
                  <UIAutoCompleteStores
                    filterBySales
                    inputPlaceholder={t('changeStore')}
                    selectedStore={selectedStore}
                    setSelectedStore={setSelectedStore}
                  />
                ) : (
                  <CurrentStoreInfoWrapper>
                    <Typography as="p" font="book" size="md">
                      {[
                        selectedStore?.storeName,
                        selectedStore?.storeDescription,
                      ]
                        .filter(el => el)
                        .join(', ')}
                    </Typography>
                    <Typography as="p" font="light" color="grey" size="xs">
                      {selectedStore?.storeAddress}
                    </Typography>
                  </CurrentStoreInfoWrapper>
                )}
              </Grid>
              {!isChangingStore && (
                <Grid>
                  <ChangeStoreButton
                    label={t('changeStore')}
                    color="primary"
                    onClick={changeStoreButtonHandler}
                  />
                </Grid>
              )}
            </Grid>
          </AutocompleteWrapper>
        )}

        <ButtonWrapper>
          <CancelButton
            label={t('cancel')}
            outlined
            disabled={confirmLoader}
            onClick={(): void => history.push('/')}
          />

          <UIButton
            label={t('confirm')}
            onClick={confirmButtonHandler}
            disabled={(selectedStore === undefined && !loggedUser.isHda) || confirmLoader}
            loading={confirmLoader || upsertHdaUserIsLoading}
          />
        </ButtonWrapper>
      </Wrapper>
    </>
  );
});

PageSettings.displayName = 'PageSettings';

export default PageSettings;
