import {
  ConfirmStockOnHand,
  EpcDecodedStockOnHand,
  StockOnHandAlignmentService,
} from '@/api/receive';
import { useSelector } from '@/hooks/useSelector';
import useDecodedNotConfirmedSohRequestBody from '@/pages/CycleCount/hooks/useDecodedNotConfirmedSohRequestBody';
import useDecodedConfirmedSohRequestBody from '@/pages/CycleCount/hooks/useDecodedSohRequestBody';
import qs from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';
import useFoundRequestBody from './useFoundRequestBody';

type NN<T> = NonNullable<T>;
type Confirm = () => Promise<void>;
type EpcCheckoutError = NN<ConfirmStockOnHand['epcCheckoutError']>;
type EpcNotFound = NN<ConfirmStockOnHand['epcNotFound']>;

const useConfirmSohAlignment = (): Confirm => {
  const location = useLocation();
  const epcFound = useFoundRequestBody();
  const epcDecodedConfirmed: EpcDecodedStockOnHand[] = useDecodedConfirmedSohRequestBody();
  const epcDecodedNotConfirmed: string[] = useDecodedNotConfirmedSohRequestBody();

  const query = React.useMemo(
    () => qs.parse(location.search),
    [location.search]
  );

  const storeCode = useSelector(
    state => state.currentStore.store?.storeCode || ''
  );

  const deviceTags = useSelector(state => state.devices.tags);

  const { notFound, found, unexpected, checkoutError } = useSelector(
    state => state.cycleCount.products
  );

  const confirm = React.useCallback(async () => {
    const stockOnHandID = query.id as string;
    const uiEpcs = [...checkoutError, ...notFound, ...found, ...unexpected]
      .flatMap(({ epcCodes }) => epcCodes)
      .map(({ epcCode }) => epcCode!);

    const epcExtraFounds = deviceTags.filter(
      epcCode => !uiEpcs.includes(epcCode)
          && !epcDecodedNotConfirmed.includes(epcCode)
          && !epcDecodedConfirmed.find(epc => epc.epcDecoded.includes(epcCode))
    ).concat(epcDecodedNotConfirmed);

    const epcCheckoutError: EpcCheckoutError = checkoutError.map(
      ({ upcCode, epcCodes }) => ({
        upcCheckoutErrorCodeFound: upcCode,
        epcCheckoutErrorFound: epcCodes.map(({ epcCode }) => epcCode!),
        epcCheckoutErrorQuantity: epcCodes.length,
      })
    );

    const epcNotFound: EpcNotFound = notFound.map(({ upcCode, epcCodes }) => ({
      upcCodeNotFound: upcCode,
      epcNotFound: epcCodes.map(({ epcCode }) => epcCode!),
      epcNotFoundQuantity: epcCodes.length,
    }));

    const body: ConfirmStockOnHand = {
      storeCode,
      stockOnHandID,
      epcFound,
      epcCheckoutError,
      epcNotFound,
      epcExtraFounds,
      epcDecoded: epcDecodedConfirmed,
    };

    await StockOnHandAlignmentService.stockonhandConfirmStockOnHandEncoded({
      requestBody: {
        base64: btoa(JSON.stringify(body)),
      },
    });
  }, [
    checkoutError,
    deviceTags,
    epcFound,
    found,
    notFound,
    query.id,
    storeCode,
    unexpected,
    epcDecodedConfirmed,
    epcDecodedNotConfirmed
  ]);

  return confirm;
};

export default useConfirmSohAlignment;
