import { HdaUserRequest } from '@/api/users';
import { useAppDispatch } from '@/app/store';
import { ModalAttention } from '@/components/layout/ModalAttention';
import { PaginationList } from '@/components/layout/PaginationList';

import { Typography } from '@/components/ui/Typography';
import { removeHDAUser, removeHdaUserFromRedux } from '@/features/profiling/hdaUsersSlice';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledGrid } from '../ProfilingUsersList/style';
import ProfilingHDAUsersRow from './ProfilingHDAUsersRow';
import { StyledGridHeader } from './style';
import { ProfilingUserListProps } from './types';

const ProfilingHDAUsersList: React.FC<ProfilingUserListProps> = ({
  users,
  setAlertSnackbarVisibility,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [id, setUserId] = useState<string>();

  const [attentionModalIsVisible, setAttentionModalVisibility] =
    useState<boolean>(false);

  const onModalCloseClick = (): void => {
    setAttentionModalVisibility(false);
    setUserId(undefined);
  };

  const onConfirmRemoveClick = async (): Promise<void> => {
    try {
      if (id) {
        await dispatch(removeHDAUser({ userId: id })).unwrap();
        dispatch(removeHdaUserFromRedux(id));
        setAttentionModalVisibility(false);
      }
    } catch (err) {
      setAlertSnackbarVisibility?.(true);
    }
  };

  return (
    <>
      <ModalAttention
        open={attentionModalIsVisible}
        onConfirmClick={onConfirmRemoveClick}
        onClose={onModalCloseClick}
        message={t('attentionDeleteUser')}
      />
      <StyledGrid>
        <StyledGridHeader>
          <Typography font="heavy"> {t('userId')} </Typography>
          <Typography font="heavy">{t('fullName')}</Typography>
          <Typography font="heavy">{t('email')}</Typography>
          <Typography font="heavy">{t('statusUser')}</Typography>
        </StyledGridHeader>

        <PaginationList
          data={users}
          pageSize={20}
          renderItem={(user: HdaUserRequest): JSX.Element =>
            user && (
              <ProfilingHDAUsersRow
                {...user}
                key={user.userId}
                setUserId={setUserId}
                setAttentionModalVisibility={setAttentionModalVisibility}
              />
            )
          }
        />
      </StyledGrid>
    </>
  );
};

export default ProfilingHDAUsersList;
