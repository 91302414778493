/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfirmCycleCount } from '../models/ConfirmCycleCount';
import type { ConfirmCycleCountEncode } from '../models/ConfirmCycleCountEncode';
import type { CycleCountItemsList } from '../models/CycleCountItemsList';
import type { ProductBrand } from '../models/ProductBrand';
import type { ProductCycleCountList } from '../models/ProductCycleCountList';
import type { ProductRequest } from '../models/ProductRequest';
import type { SearchDecodedByFilterRequest } from '../models/SearchDecodedByFilterRequest';
import type { SearchDecodedByFilterResponse } from '../models/SearchDecodedByFilterResponse';
import { request as __request } from '../core/request';

export class CycleCountService {
  /**
   * 2. SA wants to see list of last cycle count
   * Retrieve a list of Cycle Count performed by a store in a configurable number of days befor now
   * @returns CycleCountItemsList OK
   * @throws ApiError
   */
  public static async cyclecountGetCycleCountList({
    storeId,
    dayBefore,
  }: {
    /** filter for storeId **/
    storeId: any;
    /** day before now (absolute value) **/
    dayBefore: any;
  }): Promise<CycleCountItemsList> {
    const result = await __request({
      method: 'GET',
      path: `/cyclecount/getCycleCountList`,
      query: {
        storeId: storeId,
        dayBefore: dayBefore,
      },
      errors: {
        400: `bad input parameter, storeId, fromDate and toDate are mandatory`,
      },
    });
    return result.body;
  }

  /**
   * After selecting a filter, user can see the list of item to scan.
   * By passing in the appropriate options, you can search for upcCodes and we return a list of FilteredProductDeliveryItems
   * @returns ProductBrand OK
   * @throws ApiError
   */
  public static async cyclecountFindProductByStore({
    storeId,
  }: {
    /** filter for storeId **/
    storeId: any;
  }): Promise<ProductBrand> {
    const result = await __request({
      method: 'GET',
      path: `/cyclecount/findProductByStore`,
      query: {
        storeId: storeId,
      },
      errors: {
        400: `bad input parameter, List of upcCodes is mandatory`,
      },
    });
    return result.body;
  }

  /**
   * After selecting a filter, user can see the list of Product.
   * After selecting a filter, user can see the list of Product. Note - format date is yyyymmdd
   * @returns ProductCycleCountList OK
   * @throws ApiError
   */
  public static async cyclecountSearchProductByFilter({
    requestBody,
  }: {
    requestBody: ProductRequest;
  }): Promise<ProductCycleCountList> {
    const result = await __request({
      method: 'POST',
      path: `/cyclecount/searchProductByFilter`,
      body: requestBody,
      errors: {
        400: `bad input parameter, SearchProductFilters is mandatory`,
      },
    });
    return result.body;
  }

  /**
   * Decode the epcs in order to recover more info.
   * Filter attention points:<li>marketingStoryCodes and marketingThemeCodes can be used only if brandCodes has length <= 1</li>
   * @returns SearchDecodedByFilterResponse OK
   * @throws ApiError
   */
  public static async cyclecountSearchDecodedByFilter({
    requestBody,
  }: {
    requestBody: SearchDecodedByFilterRequest;
  }): Promise<SearchDecodedByFilterResponse> {
    const result = await __request({
      method: 'POST',
      path: `/cyclecount/searchDecodedByFilter`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Internal server error.`,
      },
    });
    return result.body;
  }

  /**
   * SA wants to CONFIRM a cyclecount
   * Confirm cyclecount after scan
   * @returns any Receive confirmed
   * @throws ApiError
   */
  public static async cyclecountConfirmCycleCount({
    requestBody,
  }: {
    requestBody: ConfirmCycleCount;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/cyclecount/confirmCycleCount`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
      },
    });
    return result.body;
  }

  /**
   * SA wants to CONFIRM a cyclecount
   * Confirm cyclecount after scan
   * @returns any Receive confirmed
   * @throws ApiError
   */
  public static async cyclecountConfirmCycleCountEncoded({
    requestBody,
  }: {
    requestBody: ConfirmCycleCountEncode;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/cyclecount/confirmCycleCountEncoded`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
      },
    });
    return result.body;
  }
}
