import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PromiseValue } from 'type-fest';

import { UserData, UserSlice } from '@/types/user';
import userServices from '@/services/userServices';
import { RootState } from '@/app/rootReducer';
import { SettingsService } from '@/api';
import i18n from '@/i18n';

import { setStore, removeStore } from '@/features/stores/currentStoreSlice';
import { StoreWithPrinters } from '@/types/store';

import type { ReceiveApiError as ApiError } from '@/api';
import { AppDispatch } from '@/app/store';
import {
  PermissionsByStoreCode,
  ProfilingService,
  ReceiveShipmentService,
} from '@/api/receive';

const initialState: UserSlice = {
  isLoadingLogin: false,
  isLoadingSettings: false,
  loginError: false,
  errorMessage: undefined,
  settingsError: false,
  errorCode: '',
  username: sessionStorage.getItem('userName') || undefined,
  isHda: sessionStorage.getItem('isHda') === 'true',
  roles: JSON.parse(sessionStorage.getItem('userRoles') || '[]'),
  permissions: JSON.parse(sessionStorage.getItem('userPermissions') || '[]'),
};

export const getToken = createAsyncThunk<string, string>(
  'user/getToken',
  async code => userServices.getJwtToken(code)
);

export const getSettings = createAsyncThunk<
  | PromiseValue<ReturnType<typeof SettingsService.usersettingsGetUserSettings>>
  | undefined,
  void,
  { state: RootState; rejectValue: ApiError; dispatch: AppDispatch }
>('user/getSettings', async (_, { getState, dispatch, rejectWithValue }) => {
  const {
    user: { username },
  } = getState();

  if (username) {
    try {
      const { storeCode, ...settings } =
        await SettingsService.usersettingsGetUserSettings({
          idUser: username,
        });

      const store =
        await ReceiveShipmentService.receiveshipReadStoreByStoreCode({
          storeCode,
        });

      const _settings = {
        ...settings,
        language: settings.languageIso,
        store,
        storeCode,
      };

      i18n.changeLanguage(_settings.language?.toLowerCase());

      if (_settings.store) {
        dispatch(setStore(_settings.store as StoreWithPrinters));
      }

      return _settings;
    } catch (error) {
      return rejectWithValue(error as ApiError);
    }
  }
});

export const userLogout = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch }
>('user/logout', async (_, { dispatch }) => {
  dispatch(removeStore());

  userServices.userLogout();
  sessionStorage.removeItem('linkedEventId');
});

export const userGetPermissions = createAsyncThunk<
  PermissionsByStoreCode,
  { storeCode: string },
  { rejectValue: ApiError }
>(
  'user/getPermissionsByStoreCode',
  async ({ storeCode }, { rejectWithValue }) => {
    try {
      return await ProfilingService.userGetRolesByStoreCode({ storeCode });
    } catch (error) {
      return rejectWithValue(error as ApiError);
    }
  }
);

export const userMassiveUpload = createAsyncThunk<
  PermissionsByStoreCode,
  { fileName: string; fileContent: string },
  { rejectValue: ApiError }
>(
  'user/massiveUsersUpload',
  async ({ fileName, fileContent }, { rejectWithValue }) => {
    try {
      return await ProfilingService.userMassiveUsersUpload({
        requestBody: { fileName, fileContent },
      });
    } catch (error) {
      return rejectWithValue(error as ApiError);
    }
  }
);

const getDataFromToken = (token: string): UserData => {
  const base64Url = token.split('.')[1];
  const jsonPayload = decodeURIComponent(atob(base64Url));
  return JSON.parse(jsonPayload);
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    update(state, action: PayloadAction<UserSlice>) {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getToken.pending, state => {
        state.isLoadingLogin = true;
        state.loginError = false;
        state.errorMessage = undefined;
        state.errorCode = '';
      })
      .addCase(getToken.fulfilled, (state, { payload }) => {
        const tokenData = getDataFromToken(payload);
        console.log(
          '🚀 ~ file: userSlice.ts [getToken.fulfilled.toString ~ tokenData',
          tokenData
        );

        sessionStorage.setItem('userToken', payload);
        sessionStorage.setItem('userName', tokenData.username!);
        sessionStorage.setItem('isHda', tokenData.isHda ? 'true' : 'false');
        state.username = tokenData.username;
        state.isHda = tokenData.isHda;

        state.isLoadingLogin = false;
        state.loginError = false;
        state.errorCode = '';
        state.errorMessage = undefined;
      })
      .addCase(getToken.rejected, (state, { error }) => {
        console.error('rejected', error);
        state.isLoadingLogin = false;
        state.loginError = true;
        state.errorMessage = error.message;
      })
      .addCase(userLogout.fulfilled, state => {
        state.isLoadingLogin = false;
        state.loginError = false;
        state.errorCode = '';
        state.errorMessage = undefined;
      })
      .addCase(userLogout.pending, state => {
        state.isLoadingLogin = true;
        state.loginError = false;
        state.errorCode = '';
        state.errorMessage = undefined;
      })
      .addCase(userLogout.rejected, state => {
        state.isLoadingLogin = false;
        state.loginError = true;
        state.errorMessage = undefined;
      })
      .addCase(getSettings.pending, state => {
        state.isLoadingSettings = true;
        state.settingsError = false;
      })
      .addCase(getSettings.fulfilled, state => {
        state.isLoadingSettings = false;
        state.settingsError = false;
      })
      .addCase(getSettings.rejected, state => {
        state.isLoadingSettings = false;
        state.settingsError = true;
      })
      .addCase(userGetPermissions.pending, state => {
        state.isLoadingSettings = true;
        state.settingsError = false;
      })
      .addCase(userGetPermissions.fulfilled, (state, { payload }) => {
        state.isLoadingSettings = false;
        state.settingsError = false;
        state.permissions = payload?.permissions || [];
        state.roles = payload?.roles || [];
        sessionStorage.setItem('userRoles', JSON.stringify(state.roles));
        sessionStorage.setItem(
          'userPermissions',
          JSON.stringify(state.permissions)
        );
      })
      .addCase(userGetPermissions.rejected, state => {
        state.isLoadingSettings = false;
        state.settingsError = true;
      });
  },
});

export const { update } = userSlice.actions;
export default userSlice.reducer;
