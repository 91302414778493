import { EpcDecodedCycleCountList } from '@/api/receive';
import useDecodedCycleCountRequestBody from '@/pages/CycleCount/hooks/useDecodedCycleCountRequestBody';
import React from 'react';
import { confirmCycleCount } from '@/features/cycleCount/cycleCountSlice';
import { ConfirmCycleCount } from '@/api';
import { useCycleCountContext } from '@/context/cycleCount';
import { useSelector } from '@/hooks/useSelector';
import { useAppDispatch } from '@/app/store';
import useFoundRequestBody from './useFoundRequestBody';

type Confirm = () => Promise<void>;

const useConfirmCycleCount = (): Confirm => {
  const dispatch = useAppDispatch();
  const { missingItemsBrands } = useCycleCountContext();
  const epcFoundCycleCountList = useFoundRequestBody();
  const epcDecodedCycleCountList: EpcDecodedCycleCountList[] = useDecodedCycleCountRequestBody();

  const {
    brandList,
    selectedBrands,
    selectedMarketingStories,
    selectedMarketingThemes,
    selectedEpc,
    selectedUpc,
    selectedModel,
    filterSelected,
    isMissingItemsCycleCount,
  } = useSelector(state => state.cycleCount);

  const { found, checkoutError, expectedItems } = useSelector(
    state => state.cycleCount.products
  );

  const { notFoundWithReason } = useSelector(state => state.cycleCount);

  const { store } = useSelector(state => state.currentStore);
  const { username } = useSelector(state => state.user);

  const selectedModels = React.useMemo(
    () => [...new Set(selectedModel.map(upcModel => upcModel.split('|')[1]))],
    [selectedModel]
  );

  const confirm = React.useCallback(async () => {
    const epcFoundItems = found.map(({ epcCodes }) => epcCodes).flat().length;
    const epcDecodedItems = epcDecodedCycleCountList
      .reduce((acc, item) => {
        acc = acc + item.epcDecodedQuantity
        return acc
      }, 0);

    const commodityList = brandList?.commodityList?.map(
      ({ commodityDescription }) => commodityDescription!
    );
    const marketingThemeList = selectedMarketingThemes.map(
      ({ marketingThemeCode }) => marketingThemeCode!
    );
    const marketingStoryList = selectedMarketingStories.map(
      ({ marketingStoryCode }) => marketingStoryCode!
    );
    const epcListCheckoutError = checkoutError
      .flatMap(({ epcCodes }) => epcCodes)
      .map(({ epcCode }) => epcCode);

    const requestBody: ConfirmCycleCount = {
      storeCode: store?.storeCode || '',
      webusername: username || '',
      totalItems:
        epcFoundItems +
        epcDecodedItems +
        notFoundWithReason.map(({ epcNotFound }) => epcNotFound).flat().length,
      epcFoundCycleCountList,
      epcDecodedCycleCountList,
      epcNotFoundCycleCountList: isMissingItemsCycleCount
        ? []
        : notFoundWithReason,
      commodityList: filterSelected === 0 ? commodityList : [],
      brandList:
        filterSelected === 0 && !isMissingItemsCycleCount
          ? selectedBrands.map(({ brandCode }) => brandCode!)
          : missingItemsBrands,
      marketingThemeList: filterSelected === 0 ? marketingThemeList : [],
      marketingStoryList: filterSelected === 0 ? marketingStoryList : [],
      upcList: filterSelected === 1 ? selectedUpc : [],
      epcList: filterSelected === 2 ? selectedEpc : [],
      modelList: filterSelected === 3 ? selectedModels : [],
      epcListCheckoutError,
      inStockPreCC: expectedItems,
      isMissingCC: isMissingItemsCycleCount,
    };

    await dispatch(confirmCycleCount(requestBody)).unwrap();
  }, [
    brandList?.commodityList,
    checkoutError,
    dispatch,
    epcFoundCycleCountList,
    epcDecodedCycleCountList,
    expectedItems,
    filterSelected,
    found,
    isMissingItemsCycleCount,
    missingItemsBrands,
    notFoundWithReason,
    selectedBrands,
    selectedEpc,
    selectedMarketingStories,
    selectedMarketingThemes,
    selectedModels,
    selectedUpc,
    store?.storeCode,
    username,
  ]);

  return confirm;
};

export default useConfirmCycleCount;
