import { User } from '@/api/users';
import { UIBox } from '@/components/ui/Box';
import { UIButton } from '@/components/ui/Button';
import { UICheckbox } from '@/components/ui/Checkbox';
import { UIModal } from '@/components/ui/Modal';
import { Typography } from '@/components/ui/Typography';
import { useRoleModalContext } from '@/context/roleModalContext';
import { useSelector } from '@/hooks/useSelector';
import RecapUsersList from '@/pages/ControlPanel/PageControlUsers/components/RecapUsersList';
import { theme } from '@/theme';
import { RoleModalState, SelectedStore } from '@/types/profiling';
import { Accordion, IconButton, ModalProps } from '@material-ui/core';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import styled from 'styled-components';

export interface ModalRoleProps
  extends Omit<ModalProps, 'onClose' | 'children'> {
  type: RoleModalState['type'];
  externalSecondaryFn?: (
    roles: string[],
    type: string,
    usersSelected: string[],
    title?: string,
    message?: string,
  ) => void | Promise<void>;
  externalDisabled?: boolean;
  externalTitle?: string;
  onClose: () => void;
  roles: string[];
  selectedStores: SelectedStore[];
  setSelectedStores: React.Dispatch<React.SetStateAction<SelectedStore[]>>;
  setRolesToUndo?: React.Dispatch<React.SetStateAction<SelectedStore[]>>;
  setDeletedRolesToUndo?: React.Dispatch<React.SetStateAction<SelectedStore[]>>;
  setInsertedRolesToUndo?: React.Dispatch<
    React.SetStateAction<SelectedStore[]>
  >;
  showDeletedRolesSnackbar?: React.Dispatch<React.SetStateAction<boolean>>;
  showInsertedRolesSnackbar?: React.Dispatch<React.SetStateAction<boolean>>;
  showUndoRolesSnackbar?: React.Dispatch<React.SetStateAction<boolean>>;
  setSaveEnable?: React.Dispatch<React.SetStateAction<boolean>>;
  users: User[] | undefined;
}

//#region - Styled Components
const StyledWrapper = styled(UIBox)`
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;

  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
                  overflow-y: hidden;
              }`}
`;

const StyledTitle = styled(UIBox)`
  text-align: start;
  margin: 12px 0;
`;

const StyledCloseIcon = styled(IconButton)`
  position: absolute !important;
  right: 0.5rem;
  top: 0.5rem;

  svg {
    fill: #005192;
  }

  ${theme.breakpoints.down('md')} {
    top: 0;
    right: 0.5rem;
  }
`;

const StyledContent = styled(UIBox)`
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  gap: 8px;

  padding-bottom: 32px;

  overflow-y: auto;
  overflow-x: hidden;
  & > * {
  }
`;

const StyledRole = styled(UIBox)`
  align-items: center;
`;

const StyledAccordion = styled(Accordion)`
  width: 100%;
  box-shadow: none;
  font-weight: bold;
  margin-top: 0 !important;

  ::before {
    height: 0;
  }

  .MuiAccordionSummary-root {
    border-bottom: 1px solid lightgray;
    padding: 0;

    .MuiAccordionSummary-content {
      margin: 0;
    }
  }

  .MuiAccordionDetails-root {
    padding: 0;
    overflow: hidden;
    max-height: 250px;
  }

  .kPaginationListItem {
    overflow-y: scroll;
    max-height: 150px;
  }
`;

const CTAContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;
  justify-content: center;

  button {
    width: fit-content;
  }
`;
//#endregion

const AdminManageRolesModal: React.FC<ModalRoleProps> = ({
  type,
  externalSecondaryFn,
  // externalDisabled,
  externalTitle,
  open,
  onClose,
  roles,
  users,
  // selectedStores,
}) => {
  const { t } = useTranslation();
  const user = useSelector(state => state.user);
  const [rolesChecked, setRolesChecked] = useState<string[]>([]);
  // const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [usersSelected, setUsersSelected] = useState<{ [k: string]: boolean }>(
    {}
  );

  const {
    roleModal: { rolesChecked: defaultRolesChecked },
  } = useRoleModalContext();

  useUpdateEffect(() => {
    if (users) {
      const usersSelected = users.reduce(
        (acc: { [k: string]: boolean }, user) => {
          acc = {
            ...acc,
            [String(user.userId)]: true,
          };
          return acc;
        },
        {}
      );
      setUsersSelected(usersSelected);
    }
  }, [users]);

  useEffect(() => {
    if (type === 'HYBRID') {
      setRolesChecked(defaultRolesChecked!);
    } else {
      setRolesChecked([]);
    }
  }, [defaultRolesChecked, type]);

  const onCheckboxClick = (role: string): void => {
    setRolesChecked(prevState => {
      if (prevState.includes(role)) {
        return prevState.filter(r => r !== role);
      }

      return [...prevState, role];
    });
  };

  const onApplyClick = (type: string): void => {
    const array = Object.entries(usersSelected).reduce((acc: string[], [key, value]) => {
      if (value) acc.push(key)
      return acc;
    }, []);

    const title = type === "ADD" ? t("controlUsers.processStarted.add.title") : t("controlUsers.processStarted.delete.title");
    const message = type === "ADD" ? t("controlUsers.processStarted.add.message") : t("controlUsers.processStarted.delete.message");

    externalSecondaryFn?.(
      rolesChecked,
      type,
      array,
      title,
      message
    );
    return onClose();
  };

  return (
    <UIModal
      $maxWidth="570px"
      $minWidth="570px"
      $minHeight="auto"
      $maxHeight="90vh"
      open={open}
      onClose={onClose}
    >
      <StyledWrapper>
        <StyledCloseIcon onClick={onClose}>
          <HighlightOffIcon />
        </StyledCloseIcon>
        <StyledTitle>
          <Typography size="lg" font="heavy" as="h1">
            {type === 'ADD' && `${t('modal.add.role')}`}
            {type === 'DELETE' && `${t('modal.delete.role')}`}
            {type === 'HYBRID' && `${t('modal.add.delete.role')}`}
            {type === 'EXTERNAL' && externalTitle}
          </Typography>
        </StyledTitle>
        <StyledContent>
          {roles.map((role, index) => (
            <StyledRole key={`${role}-${index}`}>
              <UICheckbox
                disabled={role === 'Admin' && !user.roles?.includes('Admin')}
                onClick={(): void => onCheckboxClick(role)}
                checked={rolesChecked.includes(role)}
              />
              <Typography>{role}</Typography>
            </StyledRole>
          ))}

          <StyledAccordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>
                {t('controlUsers.multipleRole.admin.recap', {
                  selectedUsersLenght: users?.length,
                })}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <RecapUsersList
                users={users || []}
                setUsersSelected={setUsersSelected}
                usersSelected={usersSelected}
              />
            </AccordionDetails>
          </StyledAccordion>
        </StyledContent>
        <CTAContainer>
          <UIButton
            label={t('deleteRoles')}
            onClick={(): void => onApplyClick('DELETE')}
            disabled={
              rolesChecked.length === 0 ||
              Object.values(usersSelected).filter(value => value).length === 0
            }
            outlined={true}
            style={{marginTop: '16px'}}
          />
          <UIButton
            label={t('addRoles')}
            onClick={(): void => onApplyClick('ADD')}
            disabled={
              rolesChecked.length === 0 ||
              Object.values(usersSelected).filter(value => value).length === 0
            }
            outlined={true}
            style={{marginTop: '16px'}}
          />
        </CTAContainer>
      </StyledWrapper>
    </UIModal>
  );
};

export default AdminManageRolesModal;
