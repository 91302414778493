import { User, UsersService } from '@/api/users';
import { AppRoutes } from '@/app/routers';
import { useAppDispatch } from '@/app/store';
import { CTAContainer } from '@/components/layout/CTAContainer';
import {
  ModalDataSaved,
  ModalDataSavedError as ModalDowloadUsers,
} from '@/components/layout/ModalDataSaved';
import { ModalRole } from '@/components/layout/ModalRole';
import ProfilingUsersList from '@/components/layout/ProfilingUsersList/ProfilingUsersList';
import SearchBarCheckbox from '@/components/layout/SearchBar/SearchBarCheckbox';
import SearchBarLight from '@/components/layout/SearchBar/SearchBarLight';

import { AlertSnackbar } from '@/components/ui/AlertSnackbar';
import { UIBox } from '@/components/ui/Box';
import { UIButton } from '@/components/ui/Button';
import { UILoader } from '@/components/ui/Loader';

import { UISelect } from '@/components/ui/Select';
import { Typography } from '@/components/ui/Typography';
import { useRoleModalContext } from '@/context/roleModalContext';
import { findAllRoles, initRolesState } from '@/features/profiling/rolesSlice';

import {
  clearStoresAndDetails,
  fetchUsers,
  getUserDetails,
  initProfilingUserState,
  resetProfilingUsers,
} from '@/features/profiling/usersSlice';
import { useBreadcrumbs } from '@/hooks/useBreadcrumbs';
import { useSelector } from '@/hooks/useSelector';
import AdminManageRolesModal from '@/pages/ControlPanel/PageControlUsers/components/AdminManageRolesModal';
import AdminUploadFileMenu from '@/pages/ControlPanel/PageControlUsers/components/AdminUploadFileMenu';
import { Roles, Statuses, UsersFilters } from '@/types/filters';

import { SelectOption } from '@/types/selectOption';
import { isUserHda, isUsersManager } from '@/utils/user';
import { TextField } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Autocomplete } from '@material-ui/lab';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useMount } from 'react-use';
import styled from 'styled-components';

import {
  StyledButtons,
  StyledButtonsWrapper,
  StyledButtonWithIcon,
  StyledFileButtonsWrapper,
  StyledFilterWrapper,
  StyledNoResults,
  StyledUpdateUserWrapper,
} from './style';

const StyledLoaderWrapper = styled(UIBox)`
  margin: 100px 0;
  align-items: center;
  justify-content: center;
`;

const PageControlUsers: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const {
    list: usersList,
    fetchUsersIsLoading,
    fetchUsersHasError,
    removeUserHasError,
    details,
  } = useSelector(state => state.profiling.user);

  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [showDownloadUsersModal, setShowDownloadUsersModal] =
    useState<boolean>(false);
  const [openProcessStarted, setOpenProcessStarted] = useState<boolean>(false);
  const [search, setSearch] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchIsClicked, setSearchIsClicked] = useState<boolean>(false);
  const [processStartedTitle, setProcessStartedTitle] = useState<string>('');
  const [processStartedMessage, setProcessStartedMessage] =
    useState<string>('');

  const user = useSelector(state => state.user);

  const { roleModal, setRoleModalState } = useRoleModalContext();

  useMount(async (): Promise<void> => {
    await dispatch(initProfilingUserState());
    await dispatch(getUserDetails());
    await dispatch(initRolesState());
    await dispatch(findAllRoles());
  });

  const { roles: generalRoles } = useSelector(
    ({ profiling }) => profiling?.rolesManagement
  );

  const [alertSnackbarIsVisible, setAlertSnackbarVisibility] =
    useState<boolean>(false);

  const initialFilters: UsersFilters = {
    userId: '',
    surname: '',
    storeCode: '',
    defaultStoreCode: false,
    roleName: Roles.ALL,
    salesOrganization: '',
    userStatuses: Statuses.ALL,
  };
  const [criteria, setCriteria] = useState<UsersFilters>(initialFilters);
  const [filters, setFilters] = useState<UsersFilters>(initialFilters);

  useEffect(() => {
    if (fetchUsersHasError) {
      setAlertSnackbarVisibility(true);
    }
  }, [fetchUsersHasError]);

  const handleUsers = useCallback(async (): Promise<void> => {
    dispatch(
      fetchUsers({
        filter: {
          userId: criteria.userId || undefined,
          surname: criteria.surname || undefined,
          salesOrganization: criteria.salesOrganization || undefined,
          storeCode: criteria.defaultStoreCode
            ? undefined
            : criteria.storeCode || undefined,
          defaultStoreCode: criteria.defaultStoreCode
            ? criteria.storeCode || undefined
            : undefined,
          userStatuses:
            filters.userStatuses === Statuses.ALL
              ? undefined
              : [
                  filters.userStatuses.toUpperCase() as
                    | 'ACTIVE'
                    | 'INACTIVE'
                    | 'ISDELETED',
                ],
          roleName:
            filters.roleName === Roles.ALL ? undefined : filters.roleName,
        },
      })
    );
  }, [criteria, filters, dispatch]);

  useEffect(() => {
    if (search) {
      handleUsers();
      setSearch(false);
    }
  }, [handleUsers, search]);

  useEffect(() => {
    dispatch(clearStoresAndDetails());
  }, [dispatch]);

  useBreadcrumbs([{ title: t('page.controlPanel') }]);

  const roles: SelectOption[] = useMemo(() => {
    const userRoles = generalRoles.map(role => ({
      label: t(`${role}`),
      value: role,
    }));

    userRoles.unshift({ label: t('all'), value: Roles.ALL });

    return userRoles;
  }, [generalRoles, t]);

  const statuses: SelectOption[] = useMemo(() => {
    return Object.values(Statuses).map(status => ({
      label: t(`${status}`),
      value: status,
    }));
  }, [t]);

  const onCriteriaChange = (
    key: keyof UsersFilters,
    value: string | boolean
  ): void => {
    setCriteria(prevState => ({
      ...prevState,
      [key]: value,
    }));
    setSelectedUsers([]);
  };

  const onFilterChange = (
    key: keyof UsersFilters,
    value: string | boolean
  ): void => {
    setFilters(prevState => ({
      ...prevState,
      [key]: value,
    }));
    // setSelectedUsers([]);
    setSearch(true);
  };

  const onBackClick = (): void => {
    history.push(AppRoutes.ADMIN);
  };

  const onAddUser = (): void => {
    history.push(AppRoutes.MANAGE_USERS_CREATE);
  };

  const externalFnAdd = async (
    roles: string[],
    type: string,
    usersSelected: string[],
    title?: string,
    message?: string
  ): Promise<void> => {
    try {
      const requestBody = {
        users: usersSelected || selectedUsers.map(k => k.userId!),
        roles,
      };

      switch (type) {
        case 'ADD': {
          UsersService.addUsersRolesEncoded({
            requestBody: { encodedRequest: btoa(JSON.stringify(requestBody)) },
          });
          break;
        }

        case 'DELETE': {
          UsersService.removeUsersRolesEncoded({
            requestBody: { encodedRequest: btoa(JSON.stringify(requestBody)) },
          });
          break;
        }
      }

      if (title || message) {
        setOpenProcessStarted(true);
        setProcessStartedTitle(
          title || t('controlUsers.processStarted.delete.title')
        );
        setProcessStartedMessage(
          message || t('controlUsers.processStarted.delete.message')
        );
      } else {
        setRoleModalState(prevState => ({
          ...prevState,
          rolesChecked: [],
          open: false,
        }));
      }
      resetFilters();
    } catch (e) {
      console.error(e);
    }
  };

  const handleDownloadUserList = (): void => {
    if (usersList.length > 0) {
      setShowDownloadUsersModal(true);
    }
  };

  const closeModal = (): void => {
    setShowDownloadUsersModal(false);
  };

  const handleConfirmDownloadUsersList = async (): Promise<void> => {
    const csvFile = await UsersService.downloadUsers({
      requestBody: {
        filter: {
          userId: criteria.userId || undefined,
          surname: criteria.surname || undefined,
          salesOrganization: criteria.salesOrganization || undefined,
          storeCode: criteria.defaultStoreCode
            ? undefined
            : criteria.storeCode || undefined,
          defaultStoreCode: criteria.defaultStoreCode
            ? criteria.storeCode || undefined
            : undefined,
          userStatuses:
            filters.userStatuses === Statuses.ALL
              ? undefined
              : [
                  filters.userStatuses.toUpperCase() as
                    | 'ACTIVE'
                    | 'INACTIVE'
                    | 'ISDELETED',
                ],
          roleName:
            filters.roleName === Roles.ALL ? undefined : filters.roleName,
        },
      },
    });
    const date = new Date();
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;
    const nameFile = `users_${formattedDate}.csv`;

    const blob = new Blob([csvFile], { type: 'text/csv' });
    const linkSource = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = nameFile;
    downloadLink.click();
    URL.revokeObjectURL(linkSource);
    closeModal();
  };

  const handleOpenUploadFileMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    setAnchorEl(event.currentTarget);
  };

  const resetFilters = (): void => {
    setSearchIsClicked(false);
    setCriteria(JSON.parse(JSON.stringify(initialFilters)));
    setFilters(JSON.parse(JSON.stringify(initialFilters)));
    dispatch(resetProfilingUsers());
    setSelectedUsers([]);
  };

  return (
    <>
      <StyledFilterWrapper>
        <SearchBarLight
          label=""
          value={criteria.userId}
          placeholder={t('profiling.searchById')}
          disabled={false}
          onSearch={(): void => {}}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            onCriteriaChange('userId', e.target.value)
          }
          loading={false}
          hideButton={true}
          hideSearchIcon={true}
          inputProps={{
            maxLength: 255,
            minLength: 0,
          }}
        />

        <SearchBarLight
          label=""
          value={criteria.surname}
          placeholder={t('profiling.searchBySurname')}
          disabled={false}
          onSearch={(): void => {}}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            onCriteriaChange('surname', e.target.value)
          }
          loading={false}
          hideButton={true}
          hideSearchIcon={true}
          inputProps={{
            maxLength: 255,
            minLength: 0,
          }}
        />

        <Autocomplete
          key="sales-organizations"
          value={criteria.salesOrganization}
          onChange={(_e, sale): void => {
            onCriteriaChange('salesOrganization', sale || '');
          }}
          disabled={!!criteria.storeCode}
          options={details['salesOrganization'] || []}
          getOptionLabel={(option): string => option || ''}
          getOptionSelected={(option, value): boolean => option === value}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              variant="outlined"
              label={`${t('profiling.filterBySalesOrganizations')} *`}
            />
          )}
        />

        <SearchBarCheckbox
          label=""
          value={criteria.storeCode}
          placeholder={
            criteria.defaultStoreCode
              ? t('profiling.filterByDefaultStoreCode')
              : t('profiling.filterByStoreCode')
          }
          disabled={!!criteria.salesOrganization}
          onSearch={(): void => {}}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            onCriteriaChange('storeCode', e.target.value)
          }
          onCheck={(): void => {
            if (!criteria.defaultStoreCode)
              onCriteriaChange('salesOrganization', '');
            onCriteriaChange('defaultStoreCode', !criteria.defaultStoreCode);
          }}
          loading={false}
          hideButton={true}
          hideCheckbox={false}
          isChecked={criteria.defaultStoreCode}
          helperText={t('profiling.searchByStoreCodeHelperText')}
          inputProps={{
            maxLength: 255,
            minLength: 0,
          }}
        />

        <UISelect
          selectwidth="100%"
          title=""
          variant="outlined"
          inputLabel={t('profiling.filterByRole')}
          onChange={(e: React.ChangeEvent<{ value: unknown }>): void =>
            onFilterChange('roleName', e.target.value as string)
          }
          value={filters.roleName}
          values={roles}
          marg={1}
          disabled={!searchIsClicked}
        />

        <UISelect
          selectwidth="100%"
          title=""
          variant="outlined"
          inputLabel={t('profiling.filterByStatus')}
          onChange={(e: React.ChangeEvent<{ value: unknown }>): void =>
            onFilterChange('userStatuses', e.target.value as string)
          }
          value={filters.userStatuses}
          values={statuses}
          marg={1}
          disabled={!searchIsClicked}
        />

        <StyledButtonsWrapper>
          <StyledButtons>
            <StyledButtonWithIcon
              label={t('search')}
              disabled={
                !criteria.userId &&
                !criteria.surname &&
                !criteria.salesOrganization &&
                !criteria.storeCode
              }
              onClick={(): void => {
                setSearchIsClicked(true);
                setSearch(true);
              }}
            />
            <StyledButtonWithIcon
              label={t('reset')}
              onClick={(): void => resetFilters()}
            />
          </StyledButtons>
        </StyledButtonsWrapper>
        <StyledFileButtonsWrapper>
          <StyledButtons>
            {isUsersManager(user) && !isUserHda(user) && (
              <StyledUpdateUserWrapper onClick={handleDownloadUserList}>
                <Typography
                  color={usersList.length === 0 ? 'disabled' : 'primary'}
                >
                  {t('controlUsers.download.label')}
                </Typography>
                <GetAppIcon
                  color={usersList.length === 0 ? 'disabled' : 'primary'}
                />
              </StyledUpdateUserWrapper>
            )}
            {isUsersManager(user) && !isUserHda(user) && (
              <StyledUpdateUserWrapper
                onClick={handleOpenUploadFileMenu}
                id={'upload-file-menu'}
              >
                <Typography color="primary">
                  {t('controlUsers.upload.label')}
                </Typography>
                <MoreVert />
              </StyledUpdateUserWrapper>
            )}
          </StyledButtons>
        </StyledFileButtonsWrapper>
      </StyledFilterWrapper>

      {fetchUsersIsLoading ? (
        <StyledLoaderWrapper>
          <UILoader />
        </StyledLoaderWrapper>
      ) : (
        <>
          {usersList.length > 0 ? (
            <ProfilingUsersList
              users={usersList}
              setAlertSnackbarVisibility={setAlertSnackbarVisibility}
              exportUsersSelected={setSelectedUsers}
              externalFn={externalFnAdd}
            />
          ) : (
            <StyledNoResults>
              <p className={'title'}>{t('controlUsers.noResult.title')}</p>
              <p>{t('controlUsers.noResult.message')}</p>
            </StyledNoResults>
          )}
        </>
      )}

      {isUsersManager(user) && !isUserHda(user) ? (
        <CTAContainer
          type="USER_MANAGEMENT"
          onClick={(): void => onAddUser()}
          onClickSecondary={(): void =>
            setRoleModalState({
              open: true,
              type: 'EXTERNAL',
              roles: generalRoles,
              externalSecondaryFn: (
                roles: string[],
                type: string,
                usersSelected: string[],
                title?: string,
                message?: string
              ) => externalFnAdd(roles, type, usersSelected, title, message),
              externalTitle: t('controlUsers.multipleRole.admin.title'),
              users: selectedUsers,
            })
          }
          onBackClick={(): void => onBackClick()}
          secondaryButtonLabel={t('manageRoles')}
          secondaryButtonIcon={selectedUsers?.length || 0}
          disabledSecondaryAction={selectedUsers.length === 0}
        />
      ) : (
        isUserHda(user) && (
          <CTAContainer
            type="ADD USER"
            onClick={(): void => onAddUser()}
            onBackClick={(): void => onBackClick()}
          />
        )
      )}

      {isUsersManager(user) && !isUserHda(user) && (
        <ModalDataSaved
          open={openProcessStarted}
          title={processStartedTitle}
          message={processStartedMessage}
          onClose={(): void => setOpenProcessStarted(false)}
          $minHeight={'25vh'}
        />
      )}

      {isUsersManager(user) && !isUserHda(user) ? (
        <AdminManageRolesModal
          type={roleModal.type}
          open={roleModal.open}
          roles={roleModal.roles}
          externalSecondaryFn={roleModal.externalSecondaryFn}
          externalTitle={roleModal.externalTitle}
          selectedStores={[]}
          setSelectedStores={(): void => {}}
          onClose={(): void => {
            setRoleModalState(prevState => ({
              ...prevState,
              rolesChecked: [],
              open: false,
            }));
          }}
          users={roleModal.users}
        />
      ) : (
        isUserHda(user) && (
          <ModalRole
            type={roleModal.type}
            open={roleModal.open}
            roles={roleModal.roles}
            externalFn={roleModal.externalFn}
            externalTitle={roleModal.externalTitle}
            selectedStores={[]}
            setSelectedStores={(): void => {}}
            onClose={(): void => {
              setRoleModalState(prevState => ({
                ...prevState,
                rolesChecked: [],
                open: false,
              }));
            }}
          />
        )
      )}

      {isUsersManager(user) && !isUserHda(user) && (
        <ModalDowloadUsers
          iconType="ERROR"
          $minWidth="500px"
          $minHeight="250px"
          title={t('controlUsers.download.title')}
          message={t('controlUsers.download.message')}
          open={showDownloadUsersModal}
          onClose={(): void => closeModal()}
        >
          <UIBox mt={4} alignItems="center" width="60%" alignSelf="center">
            <UIButton
              outlined
              label={t('controlUsers.download.action')}
              onClick={handleConfirmDownloadUsersList}
            />
          </UIBox>
        </ModalDowloadUsers>
      )}

      <AlertSnackbar
        open={alertSnackbarIsVisible && !!removeUserHasError}
        setIsOpen={setAlertSnackbarVisibility}
        message={removeUserHasError?.body.errorMessage || 'Generic Error'}
      />

      <AlertSnackbar
        open={alertSnackbarIsVisible && !!fetchUsersHasError}
        setIsOpen={setAlertSnackbarVisibility}
        message={fetchUsersHasError?.body.errorMessage || 'Generic Error'}
      />

      <AdminUploadFileMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
};

export default PageControlUsers;
