import { EpcDecodedStockOnHand } from '@/api/receive';
import { useSelector } from '@/hooks/useSelector';
import React from 'react';

const useDecodedConfirmedSohRequestBody = (): Array<EpcDecodedStockOnHand> => {
  const { decodedItemsConfirmed } = useSelector(
    state => state.cycleCount.products
  );

  const body: Array<EpcDecodedStockOnHand> = React.useMemo(() => {
    return (
      decodedItemsConfirmed?.map(product => ({
        upcCodeDecoded: product.upcCode || '',
        epcDecodedQuantity: product.epcCodes.length || 0,
        epcDecoded:
          product.epcCodes.map(({ epcCode }) => {
            return epcCode;
          }) || [],
      })) ?? []
    );
  }, [decodedItemsConfirmed]);

  return body;
};
export default useDecodedConfirmedSohRequestBody;
