/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HdaUserRequest } from '../models/HdaUserRequest';
import { request as __request } from '../core/request';

export class HdaUserService {
  /**
   * /users/hda/{userId} : Insert or update HDA user
   * If userId exists, updates the user. If not, inserts a new user with the ID provided in the request.
   * @returns any User inserted or updated successfully
   * @throws ApiError
   */
  public static async upsertHdaUser({
    userId,
    requestBody,
  }: {
    userId: string;
    requestBody: HdaUserRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/users/hda/${userId}`,
      body: requestBody,
      errors: {
        400: `Mandatory field missing`,
        500: `Internal Server Error`,
      },
    });
    return result.body;
  }

  /**
   * /users/hda/{userId}
   * Delete HDA user
   * @returns any User deleted successfully
   * @throws ApiError
   */
  public static async deleteHdaUser({
    userId,
  }: {
    userId: string;
  }): Promise<any> {
    const result = await __request({
      method: 'DELETE',
      path: `/users/hda/${userId}`,
      errors: {
        400: `Mandatory field missing`,
        404: `User not found. No delete performed`,
        500: `Internal Server Error`,
      },
    });
    return result.body;
  }

  /**
   * users/hda/ Retrieve HDA users
   * Retrieve a list of users. If userId is provided, returns only the matching user.
   * @returns any Users retrieved successfully
   * @throws ApiError
   */
  public static async getHdaUsers({
    userId,
  }: {
    userId?: string;
  }): Promise<any> {
    const result = await __request({
      method: 'GET',
      path: `/users/hda`,
      query: {
        userId: userId,
      },
      errors: {
        400: `Mandatory field missing`,
        500: `Internal Server Error`,
      },
    });
    return result.body;
  }
}
