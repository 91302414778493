import { useSelector } from '@/hooks/useSelector';
import React from 'react';

export const useDecodedNotConfirmedSohRequestBody = (): Array<string> => {
  const { decodedItemsNotConfirmed } = useSelector(
    state => state.cycleCount.products
  );

  const body: Array<string> = React.useMemo(() => {
    return (
      decodedItemsNotConfirmed?.reduce((acc: string[], item) => {
        acc = acc.concat(item.epcCodes.map(el => el.epcCode));
        return acc;
      }, []) ?? []
    );
  }, [decodedItemsNotConfirmed]);

  return body;
};
export default useDecodedNotConfirmedSohRequestBody;
